import React from "react"
import styled from "styled-components"
import loadable from "@loadable/component"
import { useOverlayGetBottomComponent } from "../structure/overlay.provider"
import { toPx } from "../structure/theme"
import { useUserContext } from "../navigation/user.provider"

const Chat = loadable(() => import(`./chat/chat.component`), {
  ssr: false,
})

const WebCallback = loadable(() => import(`./webCallback/webCallback.component`), {
  ssr: true,
})

const Wrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: ${props => toPx(props.bottomSpace)};
`

export default function Overlay () {
  const bottomComponent = useOverlayGetBottomComponent()
  const showBottomComponent = bottomComponent && bottomComponent.isVisible
  const bottomSpace = showBottomComponent ? bottomComponent.bottomSpace : 0
  const { context } = useUserContext()
  const { disableChat, disableWcb } = context

  return (
    <>
      <Wrapper bottomSpace={bottomSpace}>
        {!disableChat && APP_CONFIG.featureFlags.chat ? <Chat /> : null}
        {!disableWcb && APP_CONFIG.featureFlags.webCallback ? <WebCallback /> : null}
        {showBottomComponent ? <bottomComponent.Component {...bottomComponent.props} /> : null}
      </Wrapper>
      {bottomSpace ? <div style={{ height: toPx(bottomSpace) }} /> : null}
    </>
  )
}
