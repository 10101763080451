import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath(t => t(`pages:paymentStep3.path`)),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual, locales }) => {
    const getI18n = require(`../../translation/i18n`).default

    return locales.map(locale => {
      const { t } = getI18n(locale)

      return {
        path: route.createPath(isMultiLingual, t, { locale }),
        component: `./src/modules/payment/step3.page.js`,
        context: {
          locale,
          meta: {
            title: t(`pages:paymentStep3.title`),
            description: t(`pages:paymentStep3.description`),
          },
          breadcrumb: [
            { label: t(`pages:paymentStep3.breadcrumb_1`) },
            { label: t(`pages:paymentStep3.breadcrumb_2`) },
          ],
          indexed: false,
          coBrandable: true,
          simplifiedNavigation: true,
        },
      }
    })
  }
}

export default route
