import { useEffect } from "react"
import sha256 from "tiny-hashes/sha256"
import { tokenizeObject } from "../../../utils/format"
import { usePaymentContext } from "../../payment/payment.provider"
import { useCampaignProduct } from "../../campaign/campaign.utils"
import { usePrice, usePrices } from "../../product/product.utils"
import { usePageContext } from "../../navigation/pageContext.provider"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import _noop from "lodash/noop"

export function useFormatProductForTracking (product, priceProduct, list = ``, position = -1, more = {}) {
  const { standardPrice, price, betterPrice } = priceProduct

  if (!product) {
    return {}
  }

  const { offerCode, areaCode } = product.impactProduct.variants[0]

  return {
    name: product.name,
    id: product.impactProduct.impactId,
    price,
    category: product.universes[0].name,
    variant: `${offerCode}|${areaCode}`,
    dimension13: product.housingType,
    dimension14: product.tunnelSpecificity,
    dimension15: betterPrice ? `oui` : `non`,
    ...(list ? { list } : {}),
    ...(position >= 0 ? { position } : {}),
    metric2: betterPrice ? standardPrice : ``,
    ...more,
  }
}

export function formatPageNameForTraking (route, params) {
  switch (route) {
    case `home`:
      return `Homepage`
    case `product`:
      return `FP-NosClientsRecommandent`
    case `campaign`:
      return `Landing Multi produits - ${params.campaign}`
    case `coBrandHome`:
      return `Accueil cobrand - ${params.brandName}`
    case `coBrandLpm`:
      return `LPM - ${params.campaign} - ${params.brandName}`
    case `category`:
      return `Page Gamme - ${params.universes}`
    case `results`:
      return `Page Résultat - ${params.universes}`
    default:
      if (!process.env.OPTIMIZED) {
        throw new Error(`Route ${route} should not be tracked`)
      }

      return ``
  }
}

export function formatPaymentForTracking (payment) {
  if (payment.type === `stripe`) {
    return `Carte Bancaire`
  }
  switch (payment.paymentInfos.type) {
    case `DDM`:
      return `Prélévement 12x`
    case `DDQ`:
      return `Prélévement 4x`
    case `DDA`:
      return `Prélévement 1x`
    default:
      return `unknow`
  }
}

function debugGTM (data) {
  if (APP_CONFIG.gtm.debug) {
    /* eslint-disable no-console */
    console.group(`%c Push GTM event `, `background: #222; color: #bada55`)
    console.log(JSON.stringify(data, null, 4))
    console.groupEnd()
    /* eslint-enable no-console */
  }
}

export function addTagManager (dataLayer) {
  if (!window.dataLayer) {
    window.dataLayer = []
  }
  debugGTM(dataLayer)
  window.dataLayer.push(dataLayer)
}

export function addViewTracking (viewName, data) {
  addTagManager(tokenizeObject(APP_CONFIG.tracking.view[viewName], data))
}

export function addEventTracking (viewName, data) {
  addTagManager(tokenizeObject(APP_CONFIG.tracking.event[viewName], data))
}

export function addCodePromoTracking (campaignProviderState) {
  const { origin, campaigns, products, offerCode } = campaignProviderState
  if (origin === `input`) {
    addEventTracking(`eventGA`, {
      eventCategory: `Code Promo`,
      eventAction: campaigns.length || products.length ? `OK` : `KO`,
      eventLabel: offerCode,
    })
  }
}

export function formTrackingErrors (eventName, field, value, infoFunction = () => null, error = null) {
  if (error) {
    addEventTracking(`eventGA`, {
      eventCategory: eventName,
      eventAction: `MessageAlerte`,
      eventLabel: field,
    })
  }
  if (infoFunction(field, value)) {
    addEventTracking(`eventGA`, {
      eventCategory: eventName,
      eventAction: `MessageAvertisement`,
      eventLabel: field,
    })
  }
}

export function useViewPageTracking (pageName, categoryName, pageType, customerId = ``, eventName = `PageViewGA`) {
  const { isReady, step2, sessionId } = usePaymentContext()

  useEffect(() => {
    if (isReady) {
      addViewTracking(`page`, {
        eventName,
        pageName,
        categoryName,
        pageType,
        customerId,
        email: step2 && step2.email && sha256(step2.email),
        basket: sessionId ? `Avec` : `Sans`,
      })
    }
  }, [isReady, pageName, categoryName, pageType])
}

export function useViewCoBrandPageTracking (pageName, pageType, customerId = ``, eventName = `PageViewGA`) {
  const { isReady, step2, sessionId } = usePaymentContext()
  const { coBrandReady, coBrand } = useCoBrand()

  useEffect(() => {
    if (isReady && coBrandReady && coBrand) {
      addViewTracking(`page`, {
        eventName,
        pageName,
        categoryName: coBrand.brandName,
        pageType,
        customerId,
        email: step2 && step2.email && sha256(step2.email),
        basket: sessionId ? `Avec` : `Sans`,
      })
    }
  }, [isReady, pageName, coBrandReady, pageType])
}

export function useViewLPSPageTracking (campaignName) {
  const { isReady, step2, sessionId } = usePaymentContext()
  const { coBrandReady, coBrand } = useCoBrand()

  useEffect(() => {
    if (isReady && coBrandReady) {
      addViewTracking(`page`, {
        eventName: `PageViewGA`,
        pageName: coBrand ? `Parcours cobrandés` : `Campagnes`,
        categoryName: coBrand ? coBrand.brandName : campaignName,
        pageType: coBrand ? `Landing Simple` : `Landing`,
        customerId: ``,
        email: step2 && step2.email && sha256(step2.email),
        basket: sessionId ? `Avec` : `Sans`,
      })
    }
  }, [isReady, coBrandReady, campaignName])
}

export function usePaymentProductTracking (product, step, label) {
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)
  const price = usePrice(campaignProduct)
  const productTracking = useFormatProductForTracking(campaignProduct, price, ``, -1, {
    quality: 1,
  })

  useEffect(() => {
    if (campaignProductReady && campaignProduct) {
      addEventTracking(`EtapeTunnel`, {
        label,
        step,
        products: [productTracking],
      })
    }
  }, [campaignProductReady])
}

export function useEventTracking (eventName, data, listener = []) {
  useEffect(() => {
    addEventTracking(eventName, data)
  }, listener)
}

export function useEventCampaignProductsTracking (pageName, products) {
  const campaignProducts = products.map(product => useCampaignProduct(product))
  const allReady = campaignProducts.every(p => p[1])
  const prices = usePrices(campaignProducts.map(([product]) => product))

  const trackingProducts = campaignProducts.map(([product], i) =>
    useFormatProductForTracking(product, prices[i], pageName, i + 1),
  )

  useEffect(() => {
    if (allReady) {
      addEventTracking(`productImpression`, {
        products: trackingProducts,
      })
    }
  }, [allReady])
}

export function useEventProductTracking (product) {
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)
  const price = usePrice(campaignProduct)
  const productTracking = useFormatProductForTracking(campaignProduct, price)

  useEffect(() => {
    if (campaignProductReady) {
      addEventTracking(`productDetail`, {
        products: [productTracking],
      })
    }
  }, [campaignProductReady])
}

export function useProductImpressionTracking (products, pageName) {
  const { coBrandReady, coBrand } = useCoBrand()
  const prices = usePrices(products)

  const formatedProducts = products.map((product, i) =>
    useFormatProductForTracking(
      product,
      prices[i],
      coBrandReady && (typeof pageName === `function` ? pageName(coBrand) : pageName),
      i + 1,
    ),
  )

  useEffect(() => {
    if (coBrandReady && formatedProducts.length > 0) {
      addEventTracking(`productImpression`, {
        products: formatedProducts,
      })
    }
  }, [coBrandReady])
}

export function useProductDetailTracking (products) {
  const prices = usePrices(products)

  useEventTracking(`productDetail`, {
    products: products.map((product, i) => useFormatProductForTracking(product, prices[i])),
  })
}

export function addPaymentSuccessTracking (impactId, product, done) {
  const [campaignProduct, campaignProductReady] = useCampaignProduct(product)
  const priceProduct = usePrice(campaignProduct)
  const productTracking = useFormatProductForTracking(campaignProduct, priceProduct, ``, -1, {
    quantity: 1,
  })
  const ready = campaignProductReady && campaignProduct

  useEffect(() => {
    if (ready) {
      addEventTracking(`paymentSuccess`, {
        id: impactId,
        totalCommande: priceProduct.price,
        offerCode: campaignProduct.impactProduct.variants[0].offerCode,
        products: [productTracking],
      })
      done()
    }
  }, [ready])
}

export function addToCartTracking (campaignProduct) {
  const productTracking = useFormatProductForTracking(campaignProduct, ``, -1, {
    quantity: 1,
  })

  if (!productTracking) {
    return _noop
  }

  return () => {
    addEventTracking(`addToCart`, {
      products: [productTracking],
    })
  }
}

const clickProductPageTracked = [`category`, `results`, `coBrandHome`, `coBrandLpm`]

export function useProductClickTracking (product, position) {
  const { route, meta, name = `` } = usePageContext()
  const price = usePrice(product)
  const productTracking = useFormatProductForTracking(product, price, ``, position)
  const { coBrand } = useCoBrand()
  const universes = name || joinCodesTracking(product.universes.map(universe => universe.name))

  if (clickProductPageTracked.indexOf(route) < 0 || !productTracking) {
    return _noop
  }

  return () => {
    addEventTracking(`productClick`, {
      list: formatPageNameForTraking(route, {
        campaign: meta.title,
        brandName: coBrand ? coBrand.brandName : null,
        universes,
      }),
      products: [productTracking],
    })
  }
}

export function useEventProductFinderTracking (products, name, currentSelectedUniverses) {
  const productPrices = usePrices(products)

  useEffect(() => {
    const formatedProducts = products.map((product, i) =>
      useFormatProductForTracking(product, productPrices[i], name, i + 1),
    )

    addEventTracking(`productImpression`, {
      products: formatedProducts,
    })
  }, [currentSelectedUniverses])
}

export function joinCodesTracking (codes) {
  if (Array.isArray(codes)) {
    return codes.join(`_`)
  } else if (typeof codes === `object`) {
    return joinCodesTracking(Object.keys(codes))
  }

  return ``
}
