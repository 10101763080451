import { ThemeProvider as StyleComponentThemeProvider } from "styled-components"
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useCoBrand } from "../coBrand/coBrand.provider"
import _set from "lodash/set"

const mapCoBrandThemeToConfigTheme = {
  footerBgColor: `modules.navigation.footer.backgroundColor`,
  footerColor: `modules.navigation.footer.color`,
  headerBorderBottomColor: [
    `modules.navigation.header.borderBottomColor`,
    `modules.navigation.breadcrumb.backgroundColor`,
  ],
  productListItemTopBgColor: `modules.product.card.housingType.backgroundColor`,
  productListItemDescBgColor: `modules.product.card.description.backgroundColor`,
  productListItemPriceColor: `modules.product.card.price.color`,
  buttonPrimaryBgColor: [
    `modules.product.card.button.backgroundColor`,
    `modules.product.paymentButton.backgroundColor`,
    `modules.campaign.banner.anchor.backgroundColor`,
  ],
  buttonSecondaryColor: [
    `modules.product.card.buttonMoreInfos.color`,
    `modules.campaign.banner.anchor.backgroundColor`,
  ],
  wcbBgColor: [`modules.contact.wcb.backgroundColor`, `modules.contact.wcb.button.backgroundColor`],
  wcbColor: [`modules.contact.wcb.color`, `modules.contact.wcb.button.color`, `modules.product.card.description.color`],
  productPictoColor: [`modules.product.picto.universeColor`, `modules.navigation.header.phoneNumber`],
}

function merge (theme, coBrand) {
  if (!coBrand) {
    return theme
  }

  // clone deep
  const newTheme = JSON.parse(JSON.stringify(theme))

  for (const key in coBrand) {
    if (key in mapCoBrandThemeToConfigTheme) {
      if (Array.isArray(mapCoBrandThemeToConfigTheme[key])) {
        mapCoBrandThemeToConfigTheme[key].forEach(themeKey => {
          _set(newTheme, themeKey, coBrand[key])
        })
      } else {
        _set(newTheme, mapCoBrandThemeToConfigTheme[key], coBrand[key])
      }
    }
  }

  return newTheme
}

export default function ThemeProvider ({ children, theme = APP_CONFIG.theme, useCobrandTheme = true }) {
  if (!APP_CONFIG.featureFlags.co_brand) {
    return <StyleComponentThemeProvider theme={theme}>{children}</StyleComponentThemeProvider>
  }

  const { coBrand } = useCoBrand()

  const mergedTheme = useMemo(() => {
    return useCobrandTheme ? merge(theme, coBrand) : theme
  }, [useCobrandTheme, theme, coBrand])

  return <StyleComponentThemeProvider theme={mergedTheme}>{children}</StyleComponentThemeProvider>
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
  useCobrandTheme: PropTypes.bool,
}
