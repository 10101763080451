import { fetchServerSite } from "../../utils/fetch"

export function getServerProducts () {
  return fetchServerSite(`/products/active`).then(products => products.map(productNormalizer))
}

export function productSlugNormalizer (slug) {
  return `/${slug}/`
    .trim() // trim whitespaces
    .replace(/[ ]+/g, ``) // squash duplicate whitespaces
    .replace(/[/]+/g, `/`) // squash duplicate '/'
}

function productNormalizer (product) {
  if (!product) {
    return null
  }

  const { universes, linkedProducts, impactProduct = {}, blacklistedDepartments, interventions, slug } = product

  return {
    ...product,
    slug: productSlugNormalizer(slug),
    universes: universes || [],
    linkedProducts: linkedProducts || [],
    impactProduct: {
      ...impactProduct,
      variants: impactProduct.variants || [],
    },
    blacklistedDepartments: blacklistedDepartments || [],
    interventions: interventions || [],
  }
}

// Group products
// from [ { node: product }, ... ]
// to [{ universe: {}, products: []}, ...]
export function groupProductsByUniverse (productNodes) {
  const groups = []
  let product, universe, group

  for (product of productNodes) {
    for (universe of product.universes) {
      group = groups.find(({ universe: { originalId } }) => originalId === universe.originalId)

      if (group) {
        group.products.push(product)
      } else {
        groups.push({
          universe,
          products: [product],
        })
      }
    }
  }

  return groups
}
