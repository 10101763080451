import { Link as GatsbyLink } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { routes } from "./routes"
import _noop from "lodash/noop"
import { useTranslation } from "../translation/translate.component"
import { useIsMultiLingual } from "../website/locales.hook"
import { usePageContext } from "./pageContext.provider"

export default function Link ({
  itemProp,
  route,
  params = {},
  query = ``,
  children,
  href = ``,
  className,
  ariaLabel = ``,
  state = {},
  onClick = _noop,
  target,
  indexed = true,
  ...otherProps
}) {
  const { t } = useTranslation()
  const isMultiLingual = useIsMultiLingual()
  const { locale } = usePageContext()

  const props = {
    itemProp,
    className,
    onClick,
    target,
    ...otherProps,
  }

  if (ariaLabel) {
    props[`aria-label`] = ariaLabel
  }

  if (!indexed) {
    props.rel = `nofollow`
  }

  const url = href.replace(APP_CONFIG.baseUrl, ``)
  const isInternal = !url || /^\/(?!\/)/.test(url)

  if (!isInternal) {
    return (
      <a href={url} {...props} data-testid={arguments[0][`data-testid`] || undefined}>
        {children}
      </a>
    )
  }

  Object.assign(props, { state, href: url })

  if (params.overrideUrl) {
    props.href = params.overrideUrl + (query ? `?${query}` : ``)
  } else if (!url) {
    const getRoute = routes[route]

    if (!getRoute) {
      return null
    }

    props.rel = getRoute.context && getRoute.context.indexed ? `` : `nofollow`
    props.href = getRoute.createPath(isMultiLingual, t, { locale, ...params }) + (query ? `?${query}` : ``)
  }

  // Gatsby link component only works with internal routing
  if (target === `_blank`) {
    return (
      <a {...props} data-testid={arguments[0][`data-testid`] || undefined}>
        {children}
      </a>
    )
  }

  const { href: finalHref, ...gatsbyLinkProps } = props

  return (
    <GatsbyLink to={finalHref} {...gatsbyLinkProps}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  onClick: PropTypes.func,
  itemProp: PropTypes.string,
  route: PropTypes.string,
  href: PropTypes.string,
  params: PropTypes.object,
  state: PropTypes.object,
  query: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  target: PropTypes.string,
  indexed: PropTypes.bool,
}
