import React, { useContext, createContext, useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { usePageContext } from "./pageContext.provider"
import { useCoBrand } from "../coBrand/coBrand.provider"
import { useWebsiteConfiguration } from "../website/useWebsiteConfiguration.hook"

const UserContext = createContext()

export default function UserProvider ({ children }) {
  const {
    route,
    type,
    disablePhone,
    disableWcb,
    disableChat,
    pageContextReady,
    simplifiedNavigation,
  } = usePageContext()
  const { coBrand } = useCoBrand()
  const isCobrand = !!coBrand
  const { campaignPhone, salesPhone } = useWebsiteConfiguration()
  const [context, setContext] = useState({})

  useEffect(() => {
    const userContext = {}
    if (route === `campaign`) {
      userContext.simplifiedNavigation = simplifiedNavigation
    }
    if (type === `marketing_campaign`) {
      userContext.campaignPhone = isCobrand ? coBrand?.campaignPhone : campaignPhone
    }
    if (disablePhone) {
      userContext.disablePhone = disablePhone
    }
    if (disableWcb) {
      userContext.disableWcb = disableWcb
    }
    if (disableChat) {
      userContext.disableChat = disableChat
    }
    if (Object.keys(userContext).length > 0) {
      setContext(userContext)
    }
  }, [type, disablePhone, disableWcb, disableChat, pageContextReady])

  useEffect(() => {
    if (route === `home` || route === `coBrandHome`) {
      setContext({})
    }
  }, [route])

  const providedValue = useMemo(
    () => ({
      context,
      isSimplifiedNavigation: context.simplifiedNavigation || simplifiedNavigation,
      usePhoneNumber: () => {
        const salesPhoneNumber = isCobrand ? coBrand.salesPhone : salesPhone
        const campaignPhoneNumber = context.campaignPhone
        const phoneNumber = !context.disablePhone ? campaignPhoneNumber || salesPhoneNumber : ``

        return phoneNumber
      },
    }),
    [context, pageContextReady],
  )

  return <UserContext.Provider value={providedValue}>{children}</UserContext.Provider>
}

export function useUserContext () {
  return useContext(UserContext)
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
