import React, { memo, useMemo } from "react"
import { Helmet } from "react-helmet"
import { usePageContext } from "../navigation/pageContext.provider"
import { localeToWebLocale } from "../translation/translate.utils"

function SeoMeta () {
  const {
    meta: { canonical, title, description, ogUrl, ogImage },
    indexed,
    locale,
    translatePages,
  } = usePageContext()
  const localeHtml = localeToWebLocale(locale)

  const alternates = useMemo(() => {
    if (!translatePages) {
      return []
    }

    return Object.keys(translatePages).map(translatePageLang => {
      const [lang, country] = translatePageLang.split(`_`)

      return [`${lang}-${country.toLowerCase()}`, translatePages[translatePageLang]]
    })
  }, [translatePages, locale, ogUrl])

  return (
    <Helmet>
      <html lang={localeHtml} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="copyright" content={APP_CONFIG.copyright} />
      <link rel="canonical" data-testid="canonical_url" href={canonical} itemProp="url" />
      {indexed === false && <meta name="robots" content="noindex, nofollow" />}
      <meta name="google-site-verification" content={APP_CONFIG.google.siteVerification} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage || `${APP_CONFIG.baseUrl}/images/generated/logo.png`} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:site_name" content={APP_CONFIG.partnerFullName} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={localeHtml} />

      {alternates.map(([alternateLocale, alternateHref]) => (
        <link
          rel="alternate"
          hrefLang={alternateLocale}
          href={APP_CONFIG.baseUrl + alternateHref}
          key={alternateLocale}
        />
      ))}
    </Helmet>
  )
}

export default memo(SeoMeta)
