import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { slug }) => slug),
}

if (SERVER) {
  const { createFaqTagRegex } = require(`../navigation.utils`)

  route.createPages = ({ isMultiLingual }, data) => {
    const { allProduct, allCategoryPage } = data

    return allProduct.nodes.map(node => {
      const {
        impactProduct: { impactId, underwriterVerbose },
        visual: {
          file: { publicURL: ogImage },
        },
      } = node

      const categoryPages = allCategoryPage.nodes.reduce((_categoryPages, categoryPage) => {
        if (categoryPage.categoryPageProducts.find(categoryPageProduct => categoryPageProduct.id === node.id)) {
          _categoryPages.push(categoryPage)
        }

        return _categoryPages
      }, [])

      const categoryPageFirstIndexed = categoryPages.find(categoryPage => categoryPage.indexed)
      const path = route.createPath(isMultiLingual, undefined, node)

      return {
        path,
        component: `./src/modules/product/product.page.js`,
        redirectFrom: `/produit/${node.originalId}/`,
        context: {
          id: node.id,
          originalId: node.originalId,
          indexed: node.indexed,
          tagRegex: createFaqTagRegex([
            [`qui-peut-souscrire`, impactId],
            [`comment-souscrire`, impactId],
            [`conditions-du-contrat`, impactId],
            [`conditions-de-service`, impactId],
            [`qui-sommes-nous`, APP_CONFIG.faqTag, `${APP_CONFIG.faqTag}-${underwriterVerbose}`],
          ]),
          preventSticky: true,
          meta: {
            title: node.metaTitle,
            description: node.metaDescription,
            canonical: node.canonicalUrl,
            ogImage: `${APP_CONFIG.baseUrl}${ogImage}`,
          },
          breadcrumb: [
            ...(categoryPageFirstIndexed
              ? [
                  {
                    label: categoryPageFirstIndexed.breadcrumb,
                    route: `category`,
                    params: { slug: categoryPageFirstIndexed.slug },
                    children: allCategoryPage.nodes
                      .filter(n => n.id !== categoryPageFirstIndexed.id && n.indexed)
                      .map(n => ({
                        label: n.breadcrumb,
                        route: `category`,
                        params: { slug: n.slug },
                      })),
                  },
                ]
              : []),
            {
              label: node.shortName,
              route: `product`,
              params: { slug: node.slug },
              children: (categoryPageFirstIndexed ? categoryPageFirstIndexed.categoryPageProducts : [])
                .filter(categoryPageProduct => categoryPageProduct.id !== node.id && categoryPageProduct.indexed)
                .map(categoryPageProduct => ({
                  label: categoryPageProduct.shortName,
                  route: `product`,
                  params: { slug: categoryPageProduct.slug },
                })),
            },
          ],
        },
      }
    })
  }

  route.query = `
      allProduct {
        nodes {
          id
          originalId
          canonicalUrl
          slug
          shortName
          indexed
          metaTitle
          metaDescription
          impactProduct {
            impactId
            underwriterVerbose
          }
          visual {
            file {
              publicURL
            }
          }
        }
      }
      allCategoryPage {
        nodes {
          id
          slug
          breadcrumb
          indexed
          categoryPageProducts {
            id
            shortName
            indexed
            slug
          }
        }
      }
    `
}

export default route
