import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { fetch } from "@homeserve/api-fetcher"
import { getLocalesPathFromLocale, localeToWebLocale, webLocaleToLocale } from "./translate.utils"

const backend = {
  type: `backend`,
  read: function (webLocale, namespace, callback) {
    const path = getLocalesPathFromLocale(webLocaleToLocale(webLocale), namespace)

    if (CLIENT) {
      // In prod /public/locales are fetched, /src/locales in dev
      // We can't fetch as application/json to match preload links as="fetch"
      fetch(APP_CONFIG.baseUrl + path)
        .then(res => res.json())
        .then(res => callback(null, res))
        .catch(error => callback(error, null))
    } else {
      const { join } = require(`path`)
      const fs = require(`fs`)
      const dir = process.env.NODE_ENV === `production` ? `public` : `src`
      const fullPath = GATSBY_NODE ? join(__dirname, `../../../`, dir, path) : join(dir, path)

      try {
        const content = fs.readFileSync(fullPath, `utf8`)
        callback(null, JSON.parse(content))
      } catch (error) {
        console.error(fullPath, error)
        callback(error, null)
      }
    }
  },
}

let currentInstance = null

export function getCurrentI18n () {
  return currentInstance
}

export default function getI18n (requestLocale) {
  const locale = APP_CONFIG.availableLocales.includes(requestLocale) ? requestLocale : APP_CONFIG.defaultLocale

  const i18nInstance = i18n.createInstance()

  if (!GATSBY_NODE) {
    i18nInstance.use(initReactI18next)
  }

  i18nInstance.use(backend).init({
    initImmediate: false, // wait backend
    lng: localeToWebLocale(locale),
    fallbackLng: false,
    load: `currentOnly`, // don't load all languages on startup
    ns: APP_CONFIG.i18nDefaultNamespaces,
    defaultNS: `common`,
    debug: false,
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        partnerFullName: APP_CONFIG.partnerFullName,
      },
    },
    react: {
      useSuspense: false,
    },
  })

  currentInstance = {
    i18n: i18nInstance,
    t: i18nInstance.t.bind(i18nInstance),
  }

  return currentInstance
}
