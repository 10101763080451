import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { coBrand, slug }) =>
    t(`pages:coBrandLpm.path`, { coBrandSlug: coBrand.slug, campaignSlug: slug }),
  ),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual }, { allCoBrandCampaign = {} }) => {
    const getI18n = require(`../../translation/i18n`).default

    return allCoBrandCampaign.nodes.map(node => {
      const {
        id,
        originalId,
        breadcrumb,
        coBrand,
        metaTitle,
        metaDescription,
        type,
        disablePhone,
        disableChat,
        disableWcb,
      } = node
      const { originalId: coBrandOriginalId, coBrandHome } = coBrand

      const locale = node.coBrand.brandLocale
      const { t } = getI18n(locale)
      const homeBreadcrumb = t(`pages:home.breadcrumb_to_home`)

      return {
        path: route.createPath(isMultiLingual, t, { ...node, locale }),
        redirectFrom: `/campagne-cobrand/${originalId}/`,
        component: `./src/modules/coBrand/campaign/coBrandCampaign.page.js`,
        context: {
          id,
          locale,
          coBrandOriginalId,
          type,
          disablePhone,
          disableChat,
          disableWcb,
          meta: {
            title: metaTitle,
            description: metaDescription,
          },
          preventHomeBreadcrumb: true,
          breadcrumb: [
            { label: homeBreadcrumb },
            ...(coBrandHome && coBrandHome.active && coBrandHome.breadcrumb
              ? [
                  {
                    label: coBrandHome.breadcrumb,
                    route: `coBrandHome`,
                    params: coBrand,
                  },
                ]
              : []),
            {
              label: breadcrumb,
            },
          ],
          indexed: false,
        },
      }
    })
  }

  route.query = `
    allCoBrandCampaign {
      nodes {
        id
        originalId
        slug
        breadcrumb
        metaTitle
        metaDescription
        type
        disablePhone
        disableChat
        disableWcb
        coBrand {
          originalId
          slug
          brandLocale
          coBrandHome {
            active
            breadcrumb
          }
        }
      }
    }
  `
}

export default route
