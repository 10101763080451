import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const LocationContext = createContext()

export default function LocationProvider ({ location, children }) {
  return <LocationContext.Provider value={location}>{children}</LocationContext.Provider>
}

export function useLocation () {
  return useContext(LocationContext)
}

LocationProvider.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
