import React, { memo } from "react"
import { Helmet } from "react-helmet"
import styled, { createGlobalStyle } from "styled-components"
import PropTypes from "prop-types"
import { StickyContainer, Sticky } from "../components/sticky.component"
import styledNormalize from "styled-normalize"

import { tokenize } from "../utils/format"
import Footer from "../modules/navigation/footer/footer.component"
import Header from "../modules/navigation/header/header.component"
import Provider from "../modules/structure/provider.component"
import BreadcrumbLazy from "../modules/navigation/breadcrumb/breadcrumbLazy.component"
import { colors, modules } from "../modules/structure/theme"
import SeoMeta from "../modules/seo/seoMeta.component"
import Overlay from "../modules/contact/overlay.component"
import { getLocalesPathFromLocale } from "../modules/translation/translate.utils"
import loadable from "@loadable/component"
import { Spacer } from "../modules/structure/theme/styles.component"

const ToastContainer = loadable(() => import(`../modules/information/toaster.component`), {
  ssr: false,
})

const FONT_TYPE = `font/otf`

function fontUrl (family, weight) {
  return `${APP_CONFIG.basePath}/fonts/${family}-${weight}.otf`
}

function fontFace (family, weight) {
  return `
    @font-face {
      font-family: ${family};
      src: url('${fontUrl(family, weight)}') format('truetype');
      font-weight: ${weight};
      font-display: fallback;
    }
  `
}

const fontFamilyWeight = Object.keys(APP_CONFIG.theme.fonts)
  // @todo remove this condition when fontfamily and font size will be separated
  .filter(fontKey => fontKey !== `sizes`)
  .flatMap(fontKey => {
    const { family, weight: weights } = APP_CONFIG.theme.fonts[fontKey]

    return weights.map(weight => [family, weight])
  })

export const fontFaces = fontFamilyWeight.reduce(
  (_fontFaces, [family, weight]) => _fontFaces + fontFace(family, weight),
  ``,
)

const fontUrls = fontFamilyWeight.map(([family, weight]) => fontUrl(family, weight))

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  
  html, body {
    margin: 0;
    font-family: ${props => props.theme.fonts.primary.family};
    font-size: ${props => props.theme.fonts.sizes.regular};
    color: ${colors(`grey.g800`)};
    font-weight: 500;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    background-color: ${modules(`structure.page.background`)};
  }
  
  #___gatsby > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* css trick to target only ie11 and fix footer on top */
  /* stylelint-disable-next-line selector-type-no-unknown */
  _:-ms-fullscreen, :root #___gatsby > div { 
    display: block;
    flex-direction: unset;
    min-height: unset;
  }
  
  iframe {
    max-width: 100%;
  }
  
  ul {
    margin: 0;
  }
  
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    white-space: pre-wrap;
  }

  .grecaptcha-badge {
    z-index: 100;
  }

  b, strong {
    font-weight: 900;
  }
  
  /* No way to customize this element via swipejs react elements */
  .swipe-wrap {
    display: flex;
    flex-direction: row;
  }
  
  ${process.env.NODE_ENV === `production` &&
    process.env.ENVIRONMENT === `mock` &&
    `
    *,:before, :after {
      transition: none !important;
      animation: none !important;
    }
  `}
`

const HTMLHeader = styled.header`
  z-index: 2;
  position: relative;
`

const ContainerHeader = styled.div`
  position: relative;
  z-index: 2;
`

const fullPageStyle = {
  display: `flex`,
  flexDirection: `column`,
}

function Layout ({ pageContext, children, location }) {
  const { preventSticky = false, breadcrumb, locale } = pageContext

  const dataLayerSnippet = `window.dataLayer = [{'gtm.start': new Date().getTime(),event:'gtm.js'}]`

  return (
    <Provider pageContext={pageContext} location={location}>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
        <style>{fontFaces}</style>
        <script type="text/javascript">{dataLayerSnippet}</script>
        {fontUrls.map(url => (
          <link as="font" rel="preload" href={url} key={url} type={FONT_TYPE} crossOrigin="anonymous" />
        ))}
        {APP_CONFIG.i18nDefaultNamespaces.map(namespace => (
          <link
            key={namespace}
            as="fetch"
            rel="preload"
            href={APP_CONFIG.basePath + getLocalesPathFromLocale(locale, namespace)}
            crossOrigin="anonymous"
          />
        ))}
        {Object.values(APP_CONFIG.dnsPrefetch || {}).map(dnsPrefetch => (
          <React.Fragment key={dnsPrefetch}>
            <link rel="preconnect" href={dnsPrefetch} key={dnsPrefetch} />
            <link rel="dns-prefetch" href={dnsPrefetch} key={dnsPrefetch} />
          </React.Fragment>
        ))}
      </Helmet>
      <noscript>
        <iframe
          src={tokenize(APP_CONFIG.gtm.urlNoscript, {
            token: APP_CONFIG.gtm.token,
          })}
          height="0"
          width="0"
          style={{ display: `none`, visibility: `hidden` }}
        />
      </noscript>
      <GlobalStyle />
      <SeoMeta />
      {(preventSticky || !APP_CONFIG.featureFlags.stickyHeader) && (
        <>
          <HTMLHeader style={fullPageStyle}>
            <Header isSticky={false} />
          </HTMLHeader>
          {breadcrumb && <BreadcrumbLazy items={breadcrumb} />}
          {children}
        </>
      )}
      {!preventSticky && APP_CONFIG.featureFlags.stickyHeader && (
        <StickyContainer style={fullPageStyle}>
          <HTMLHeader>
            <Sticky>
              {({ style, distanceFromTop }) => (
                <ContainerHeader style={style}>
                  <Header isSticky={distanceFromTop < 0} />
                </ContainerHeader>
              )}
            </Sticky>
          </HTMLHeader>
          {breadcrumb && <BreadcrumbLazy items={breadcrumb} />}
          {children}
        </StickyContainer>
      )}
      <Spacer />
      <Footer />
      <Overlay />
      <ToastContainer />
    </Provider>
  )
}

Layout.propTypes = {
  pageContext: PropTypes.shape({
    preventSticky: PropTypes.bool,
    breadcrumb: PropTypes.array,
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

export default memo(Layout)
