import home from "./routes/home"
import product from "./routes/product"
import category from "./routes/category"
import campaign from "./routes/campaign"
import coBrand from "./routes/coBrand"
import coBrandHome from "./routes/coBrandHome"
import coBrandLps from "./routes/coBrandLps"
import coBrandLpm from "./routes/coBrandLpm"
import coBrandTunnelAgent from "./routes/coBrandTunnelAgent"
import desinscriptionEmail from "./routes/desinscriptionEmail"
import politiqueDeDonneesPersonnelles from "./routes/politiqueDeDonneesPersonnelles"
import politiqueDesCookies from "./routes/politiqueDesCookies"
import conditionsJuridiques from "./routes/conditionsJuridiques"
import aPropos from "./routes/aPropos"
import mentionsLegales from "./routes/mentionsLegales"
import stopMailing from "./routes/stopMailing"
import faq from "./routes/faq"
import commentCaMarche from "./routes/commentCaMarche"
import contact from "./routes/contact"
import contactConfirmation from "./routes/contactConfirmation"
import emptyPage from "./routes/emptyPage"
import sitemap from "./routes/sitemap"
import paymentStep1 from "./routes/paymentStep1"
import paymentStep2 from "./routes/paymentStep2"
import paymentStep3 from "./routes/paymentStep3"
import paymentStep4 from "./routes/paymentStep4"
import paymentStepLoading from "./routes/paymentStepLoading"
import paymentSuccess from "./routes/paymentSuccess"
import paymentSuccessNoPolicy from "./routes/paymentSuccessNoPolicy"
import paymentFail from "./routes/paymentFail"
import results from "./routes/results"
import embeddedDownload from "./routes/embeddedDownload"
import oldBrowser from "./routes/oldBrowser"

export const routes = {
  home,
  product: product,
  category: category,
  campaign: APP_CONFIG.featureFlags.page_campaign && campaign,
  coBrand: APP_CONFIG.featureFlags.co_brand && process.env.ENVIRONMENT === `mock` && coBrand,
  coBrandHome: APP_CONFIG.featureFlags.co_brand && coBrandHome,
  coBrandLps: APP_CONFIG.featureFlags.co_brand && coBrandLps,
  coBrandLpm: APP_CONFIG.featureFlags.co_brand && coBrandLpm,
  coBrandTunnelAgent: APP_CONFIG.featureFlags.co_brand && APP_CONFIG.featureFlags.tunnel_agent && coBrandTunnelAgent,
  desinscriptionEmail: APP_CONFIG.featureFlags.page_desinscription_email && desinscriptionEmail,
  politiqueDeDonneesPersonnelles:
    APP_CONFIG.featureFlags.page_politique_de_donnees_personnelles && politiqueDeDonneesPersonnelles,
  politiqueDesCookies: APP_CONFIG.featureFlags.page_politique_des_cookies && politiqueDesCookies,
  conditionsJuridiques: APP_CONFIG.featureFlags.page_conditions_juridiques && conditionsJuridiques,
  aPropos: APP_CONFIG.featureFlags.page_a_propos && aPropos,
  mentionsLegales: APP_CONFIG.featureFlags.page_mentions_legales && mentionsLegales,
  stopMailing: APP_CONFIG.featureFlags.page_stop_mailing && stopMailing,
  faq: APP_CONFIG.featureFlags.page_faq && faq,
  commentCaMarche: APP_CONFIG.featureFlags.page_comment_ca_marche && commentCaMarche,
  contact: APP_CONFIG.featureFlags.page_contact && contact,
  contactConfirmation: APP_CONFIG.featureFlags.page_contact && contactConfirmation,
  emptyPage: process.env.ENVIRONMENT === `mock` && emptyPage,
  sitemap: APP_CONFIG.featureFlags.page_sitemap && sitemap,
  paymentStep1,
  paymentStep2,
  paymentStep3,
  paymentStep4,
  paymentStepLoading,
  paymentSuccess,
  paymentSuccessNoPolicy,
  paymentFail,
  results,
  embeddedDownload,
  oldBrowser,
}
