import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Section from "../../../components/section.component"
import { fontSizes, modules, useThemeContext } from "../../structure/theme"
import { tokenizeObject } from "../../../utils/format"
import SeoSchema from "../../seo/seoSchema.component"
import BreadcrumbItem from "./breadcrumbItem.component"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import useNavigation from "../useNavigation"

const Wrapper = styled.div`
  display: none;

  ${breakpoint(`medium`)`
    display: flex;
    color: white;
    font-size: ${fontSizes(`small`)};
    padding-bottom: ${modules(`navigation.header.marginBottom`)};
    ${props =>
      props.compensateMenu &&
      `
      padding-top: ${APP_CONFIG.theme.modules.navigation.header.marginBottom};
    `}
  `}
`

const List = styled.ul`
  padding: 0;
  position: relative;
  width: 100%;
`

export default function Breadcrumb ({ items }) {
  const { coBrand } = useCoBrand()
  const { resolveRouteToAbsolute } = useNavigation()
  const theme = useThemeContext()

  const [allItems, seoItems] = useMemo(() => {
    if (items.length === 0) {
      return [[], []]
    }

    const _seoItems = items.map(({ label, route, params }, i) =>
      tokenizeObject(APP_CONFIG.seo.schema.breadcrumbItem, {
        label,
        position: i + 1,
        url: (route && resolveRouteToAbsolute(route, params)) || ``,
      }),
    )

    return [items, _seoItems]
  }, [items])

  if (allItems.length === 0) {
    return null
  }

  return (
    <Section data-testid="breadcrumb" bgWidth="full" bgColor={theme.modules.navigation.breadcrumb.backgroundColor}>
      <Wrapper compensateMenu={!APP_CONFIG.theme.modules.navigation.header.displayMargin && !coBrand}>
        <SeoSchema name="breadcrumb" breadcrumbItems={seoItems} />
        <List>
          {allItems.map((item, index) => (
            <BreadcrumbItem key={item.label} {...item} isLast={index === items.length} />
          ))}
        </List>
      </Wrapper>
    </Section>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}
