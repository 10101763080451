import React, { useContext, createContext, useEffect, useState, useMemo } from "react"
import { useCookieStorage, useLocalStorage } from "@homeserve/react-storage-provider"
import PropTypes from "prop-types"
import { usePageContext } from "../navigation/pageContext.provider"
import { useQueryParam, StringParam } from "use-query-params"
import { getBasketFromEncryptId } from "./payment.api"
import { useLocation } from "../navigation/location.provider"
import { useCoBrand } from "../coBrand/coBrand.provider"
import useNavigation from "../navigation/useNavigation"

const PaymentContext = createContext()

const initialPaymentState = {
  step1: null,
  step2: null,
}

const initialBasketState = {
  product: null,
  sessionId: ``,
}

export default function PaymentProvider ({ children }) {
  const [internalReady, setInternalReady] = useState(false)
  const { isReady, read, write } = useCookieStorage()
  const { isReady: isReadyLocal, read: readLocal, write: writeLocal } = useLocalStorage()
  const { activateSessionCoBrand, coBrandReady } = useCoBrand()
  const { route } = usePageContext()
  const [codeBasketReady, setCodeBasketReady] = useState(route !== `paymentStep3`)
  const paymentCookie = read(`tunnelCustomer`)
  const payment = paymentCookie || initialPaymentState
  const basket = readLocal(`basket`) || initialBasketState
  const { step1, step2 } = payment
  const { product, sessionId } = basket
  const location = useLocation()
  const [sessionIdCode] = useQueryParam(`basket`, StringParam)
  const { navigate } = useNavigation()

  useEffect(() => {
    if (!isReadyLocal || !isReady || codeBasketReady) {
      return
    }

    if (route !== `paymentStep3`) {
      setCodeBasketReady(true)

      return
    }

    window.history.replaceState(null, null, window.location.pathname)
    if (sessionIdCode) {
      getBasketFromEncryptId(sessionIdCode)
        .then(data => {
          writeLocal(`basket`, {
            product: data.product,
            sessionId: data.sessionId,
          })
          write(`tunnelCustomer`, {
            step1: data.step1,
            step2: data.step2,
          })
          setCodeBasketReady(true)
        })
        .catch(_ => {
          setCodeBasketReady(true)
          if (!step2) {
            return navigate(`paymentStep2`)
          }
        })
    } else {
      setCodeBasketReady(true)
    }
  }, [isReady, isReadyLocal, sessionIdCode, codeBasketReady])

  useEffect(() => {
    if (!codeBasketReady || !isReadyLocal || !isReady) {
      return
    }

    if (!route.includes(`paymentStep`)) {
      setInternalReady(true)

      return
    }

    if (!product) {
      return navigate(`home`)
    }

    if (route === `paymentStep2` && !step1) {
      return navigate(`paymentStep1`)
    }

    if (route === `paymentStep3` && !step2) {
      return navigate(`paymentStep2`)
    }

    if (route === `paymentStep4` && location.action !== `PUSH`) {
      return navigate(`paymentStep3`)
    }

    setInternalReady(true)
  }, [codeBasketReady, isReady, isReadyLocal, route, payment, basket])

  if (APP_CONFIG.featureFlags.co_brand) {
    useEffect(() => {
      if (coBrandReady && product && product.impactProduct.variants[0].areaCode && route.includes(`paymentStep`)) {
        activateSessionCoBrand(product.impactProduct.variants[0])
      }
    }, [product, coBrandReady])
  }

  const methods = useMemo(
    () => ({
      setProduct: (_product = {}) => writeLocal(`basket`, { ...basket, product: _product }),
      setStep1: (_step1 = {}) => write(`tunnelCustomer`, { ...payment, step1: _step1 }),
      setStep2: (_step2 = {}) => write(`tunnelCustomer`, { ...payment, step2: _step2 }),
      setsessionId: (_sessionId = ``) => writeLocal(`basket`, { ...basket, sessionId: _sessionId }),
      setStep2AndSessionId: (_step2 = {}, _sessionId = ``) => {
        writeLocal(`basket`, { ...basket, sessionId: _sessionId })
        write(`tunnelCustomer`, { ...payment, step2: _step2 })
      },
      removePostPayment: () =>
        write(`tunnelCustomer`, {
          step1: payment.step1,
          step2: payment.step2,
        }),
    }),
    [payment, write, basket],
  )

  return (
    <PaymentContext.Provider
      value={{
        isReady: internalReady,
        product,
        step1,
        step2,
        sessionId,
        ...methods,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export function usePaymentContext () {
  return useContext(PaymentContext)
}

PaymentProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
