import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { slug }) => slug),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual, locales }, { allCategoryPage }) => {
    const getI18n = require(`../../translation/i18n`).default
    const { t } = getI18n()

    return allCategoryPage.nodes.map(node => ({
      path: route.createPath(isMultiLingual, t, node),
      component: `./src/modules/category/category.page.js`,
      redirectFrom: `/gamme/${node.originalId}/`,
      context: {
        id: node.id,
        originalId: node.originalId,
        indexed: node.indexed,
        meta: {
          title: node.metaTitle,
          description: node.metaDescription,
        },
        breadcrumb: [
          {
            label: node.breadcrumb,
            route: `category`,
            params: { slug: node.slug },
            children: allCategoryPage.nodes
              .filter(n => n !== node && n.indexed)
              .map(n => ({
                label: n.breadcrumb,
                route: `category`,
                params: { slug: n.slug },
              })),
          },
        ],
      },
    }))
  }

  route.query = `
      allCategoryPage {
        nodes {
          id
          originalId
          slug
          breadcrumb
          metaTitle
          metaDescription
          indexed
        }
      }
    `
}

export default route
