import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { slug }) =>
    t(`pages:coBrandHome.path`, {
      coBrandSlug: slug,
    }),
  ),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual, locales }, { allCoBrand }) => {
    const getI18n = require(`../../translation/i18n`).default

    return allCoBrand.nodes
      .filter(node => node.coBrandHome && node.coBrandHome.active)
      .map(node => {
        const locale = node.brandLocale
        const { t } = getI18n(locale)

        return {
          path: route.createPath(isMultiLingual, t, { ...node, locale }),
          redirectFrom: `/cobrand/${node.originalId}/`,
          component: `./src/modules/coBrand/home/home.page.js`,
          context: {
            id: node.id,
            coBrandOriginalId: node.originalId,
            locale,
            meta: {
              title: node.coBrandHome.metaTitle,
              description: node.coBrandHome.metaDescription,
            },
            preventHomeBreadcrumb: true,
            breadcrumb: [{ label: t(`pages:home.breadcrumb_to_home`) }, { label: node.coBrandHome.breadcrumb }],
            translatable: true,
            translatePageId: `coBrandHome-${node.brandCode}`,
          },
        }
      })
  }

  route.query = `
    allCoBrand {
      nodes {
        coBrandHome {
          active
          metaTitle
          metaDescription
          breadcrumb
        }
        id
        brandName
        brandCode
        brandLocale
        originalId
        slug
      }
    }
  `
}

export default route
