const route = {
  createPath: () => `/embedded/download/`,
}

if (SERVER) {
  route.createPages = () => ({
    path: route.createPath(),
    component: `./src/modules/download/embeddedDownload.page.js`,
    context: {
      preventLayout: true,
      indexed: false,
    },
  })
}

export default route
