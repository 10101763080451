import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

const PageContext = createContext()

// update a ref is instant
// update a state is "1 render" long
// we need to instant reset pageLoaded on navigation
//
// solution:
// we store the location in a state and in the ref
// we update the state in hook (1 render long) and the ref (instantly)
// if ref !== state, first render didn't happen yet. if ref === state, 1st render happened

export default function PageContextProvider ({ context, children }) {
  const [currentPageId, setCurrentPageId] = useState(context.pageId)
  const ref = useRef(context.pageId)
  ref.current = context.pageId
  const pageContextReady = ref.current === currentPageId

  useEffect(() => {
    setCurrentPageId(ref.current)
  }, [ref.current])

  return <PageContext.Provider value={{ ...context, pageContextReady }}>{children}</PageContext.Provider>
}

export function usePageContext () {
  return useContext(PageContext)
}

PageContextProvider.propTypes = {
  context: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
