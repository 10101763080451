import slugifyLib from "slugify"
import { useDateOrMockDate } from "./time"

function priceForXMonth (price = 0, month = 12) {
  return Math.round((price / month) * 100) / 100
}

export function formatPrice (price, month = 1) {
  const priceString = String(priceForXMonth(price, month)).replace(`.`, `,`)

  if (/,\d{1}$/.test(priceString)) {
    return priceString + `0`
  }

  return priceString
}

export function formatPriceAnnually (price) {
  return formatPrice(price, 1)
}

export function formatPriceMonthly (price) {
  return formatPrice(price, 12)
}

function formatNumber2digits (number) {
  return number >= 10 ? number : `0${number}`
}

export function formatDatetime (datetime, format = `dd/mm/yyyy`) {
  const Date = useDateOrMockDate()
  const date = datetime ? new Date(datetime) : new Date()
  let output = format.toLowerCase()

  if (output.includes(`dd`)) {
    const day = date.getDate()
    output = output.replace(`dd`, formatNumber2digits(day))
  }

  if (output.includes(`mm`)) {
    const month = date.getMonth() + 1
    output = output.replace(`mm`, formatNumber2digits(month))
  }

  if (output.includes(`yyyy`)) {
    output = output.replace(`yyyy`, date.getFullYear())
  }

  if (output.includes(`ii`)) {
    const minutes = date.getMinutes()
    output = output.replace(`ii`, formatNumber2digits(minutes))
  }

  if (output.includes(`hh`)) {
    const hours = date.getHours()
    output = output.replace(`hh`, formatNumber2digits(hours))
  }

  if (output.includes(`ss`)) {
    const seconds = date.getSeconds()
    output = output.replace(`ss`, formatNumber2digits(seconds))
  }

  return output
}

export function formatNow () {
  return formatDatetime()
}

export function firstLetterUppercase (value) {
  if (!value) {
    return ``
  }

  return value[0].toUpperCase() + value.substr(1)
}

const TOKEN_STRING_REGEXP = /%\(([\w]+)\)s/
const TOKEN_OBJECT_REGEXP = /%\(([\w]+)\)o/
const TOKEN_ARRAY_REGEXP = /%\(([\w]+)\)a/
const TOKEN_NUMBER_REGEXP = /%\(([\w]+)\)n/

export function tokenize (value, tokens = {}) {
  const fullTokens = {
    partnerFullName: APP_CONFIG.partnerFullName,
    baseUrl: APP_CONFIG.baseUrl,
    siteUrl: APP_CONFIG.siteUrl,
    logoUrl: APP_CONFIG.baseUrl + `/images/generated/logo.png`,
    ...tokens,
  }

  let matches
  while ((matches = TOKEN_STRING_REGEXP.exec(value)) !== null) {
    value = value.replace(matches[0], fullTokens[matches[1]] || ``)
  }

  const matchObject = TOKEN_OBJECT_REGEXP.exec(value)
  if (matchObject) {
    value = fullTokens[matchObject[1]]
  }

  const matchArray = TOKEN_ARRAY_REGEXP.exec(value)
  if (matchArray) {
    value = fullTokens[matchArray[1]]
  }

  const matchNumber = TOKEN_NUMBER_REGEXP.exec(value)
  if (matchNumber) {
    value = fullTokens[matchNumber[1]]
  }

  return value
}

export function tokenizeObject (obj, tokens = {}) {
  const resultObject = {}
  for (const key in obj) {
    if (typeof obj[key] === `object`) {
      resultObject[key] = tokenizeObject(obj[key], tokens)
    } else {
      resultObject[key] = tokenize(obj[key], tokens)
    }
  }

  return resultObject
}

export function slugify (str) {
  // Convert special  accents, swap ñ for n, etc
  const from = `ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;`
  const to = `AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------`

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), `g`), to.charAt(i))
  }

  return slugifyLib(str, {
    lower: true,
    strict: true,
  })
}

export function inlineTemplateString (templateString) {
  return templateString.replace(/\n/gm, ``)
}

export function formatAddress (streetNumber, street) {
  const safeStreetNo = streetNumber || ``
  const safeStreet = street || ``
  const formatted = APP_CONFIG.country === `fr` ? `${safeStreetNo} ${safeStreet}` : `${safeStreet} ${safeStreetNo}`

  return formatted.trim()
}

export function formatPostalCodeAndCity (postCode, town) {
  return `${postCode || ``} ${town || ``}`.trim()
}
