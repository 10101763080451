import { useMemo } from "react"
import {
  firstLetterUppercase,
  formatDatetime,
  formatPrice,
  formatPriceAnnually,
  formatPriceMonthly,
} from "../../utils/format"

import { useCampaignContext } from "../campaign/campaign.provider"
import { useEndPriceValidityDateHook } from "../website/useEndPriceValidityDate.hook"
import { useBrandName } from "../branding/branding.hooks"
import { useTranslation } from "../translation/translate.component"
import { graphql, useStaticQuery } from "gatsby"

export const ANIMATION_TIME = 0.5

export const TUNNEL_SPECIFICITY_NONE = ``
export const TUNNEL_SPECIFICITY_OWNER = `owner`
export const TUNNEL_SPECIFICITY_OCCUPANT = `occupant`
export const TUNNEL_SPECIFICITY_RENTER = `renter`
export const TUNNEL_SPECIFICITY_OTHER = `other`

export const HOUSINGTYPE_MAISON = `maison`
export const HOUSINGTYPE_APPARTEMENT = `appartement`
export const HOUSINGTYPE_MIXTE = `mixte`

export const PRINCIPAL_HOUSING_FIRST = `RP`
export const PRINCIPAL_HOUSING_SECOND = `RS`

export const WATER_METER_POSITION_OUTSIDE = `E`
export const WATER_METER_POSITION_INSIDE = `I`
export const WATER_METER_POSITION_OTHER = `N`

export function getWaterMeterPositions (t) {
  return [
    {
      label: t(`common:payment.page_step1_watermeter_choice_outside`),
      value: WATER_METER_POSITION_OUTSIDE,
    },
    {
      label: t(`common:payment.page_step1_watermeter_choice_inside`),
      value: WATER_METER_POSITION_INSIDE,
    },
    {
      label: t(`common:payment.page_step1_watermeter_choice_other`),
      value: WATER_METER_POSITION_OTHER,
    },
  ]
}

export function formatHousingType (housingType) {
  switch (housingType) {
    case `M`:
      return HOUSINGTYPE_MAISON
    case `A`:
      return HOUSINGTYPE_APPARTEMENT
    default:
      return HOUSINGTYPE_MIXTE
  }
}

export function waterMeterPositionToString (t, waterMeterPosition) {
  if (!waterMeterPosition) {
    return ``
  }

  return getWaterMeterPositions(t).find(({ value }) => value === waterMeterPosition).label
}

export function specificityToString (t, specificity, capitalize = false) {
  let text

  switch (specificity) {
    case TUNNEL_SPECIFICITY_OCCUPANT:
      text = t(`common:payment.specificity_occupant`)
      break
    case TUNNEL_SPECIFICITY_OWNER:
      text = t(`common:payment.specificity_owner`)
      break
    case TUNNEL_SPECIFICITY_RENTER:
      text = t(`common:payment.specificity_renter`)
      break
    case TUNNEL_SPECIFICITY_OTHER:
      text = t(`common:payment.specificity_other`)
      break
    default:
      text = ``
  }

  return capitalize ? firstLetterUppercase(text) : text
}

export function getRedirectProductPerSpecificity (product, specificity) {
  switch (specificity) {
    case TUNNEL_SPECIFICITY_OCCUPANT:
      return product.occupantRedirectProduct
    case TUNNEL_SPECIFICITY_OWNER:
      return product.ownerRedirectProduct
    case TUNNEL_SPECIFICITY_RENTER:
      return product.renterRedirectProduct
    default:
      return product.otherRedirectProduct
  }
}

export function principalHousingToString (t, principalHousing) {
  return principalHousing === PRINCIPAL_HOUSING_FIRST ? t(`common:generic.yes`) : t(`common:generic.no`)
}

export function housingTypeToString (t, housingType) {
  if (!housingType) {
    return ``
  }

  if (housingType === HOUSINGTYPE_MAISON) {
    return t(`common:generic.housing_type_house`)
  }

  return t(`common:generic.housing_type_flat`)
}

export const getTokenFromUnderwriterId = product => {
  switch (product.impactProduct.underwriterId) {
    case `011`:
      return APP_CONFIG.stripe.token.allianz
    case `002`:
      return APP_CONFIG.stripe.token.amtrust
    case `010`:
      return APP_CONFIG.stripe.token.axa
    case `012`:
      return APP_CONFIG.stripe.token.amtrust_be
    default:
      throw new Error(`The underwriterId ${product.impactProduct.underwriterId} is not available`)
  }
}

export function addUniverseSlugsToProduct (product, universesSlug) {
  const universes = (product.universes || []).map(universe => {
    if (!universe.slug) {
      universe.slug = universesSlug[universe.id]
    }

    return universe
  })
  const otherProducts = [
    `occupantRedirectProduct`,
    `otherRedirectProduct`,
    `ownerRedirectProduct`,
    `renterRedirectProduct`,
  ]
  otherProducts.forEach(p => {
    if (product[p]) {
      product[p] = addUniverseSlugsToProduct(product[p], universesSlug)
    }
  })

  return {
    ...product,
    universes,
  }
}

export function transformRedirectProduct (product) {
  return product
    ? {
        name: product.name,
        slug: product.slug,
      }
    : null
}

export const civilityToString = (value, t) => {
  switch (value) {
    case `mme`:
      return firstLetterUppercase(t(`common:tunnel_agent.step3_identity_mme_small`))
    case `mr`:
      return firstLetterUppercase(t(`common:tunnel_agent.step3_identity_mr_small`))
    default:
      return firstLetterUppercase(value)
  }
}

const PERCENT_MINI_TO_APPLY_VOUCHER = 1 - 0.75
const PRICE_MIN_TO_ACCEPT_ALL_PAYMENTS = 12

export function getPrice (product, voucher) {
  if (!product) {
    return {}
  }

  const { standardPrice, offerPrice } = product.impactProduct.variants[0]
  let promoUnit = `€`
  let diff = standardPrice - offerPrice
  let promoValue = diff
  let price = Math.min(offerPrice, standardPrice)
  let betterPrice = diff > 0
  let voucherApplied = false

  if (voucher && voucher.minCartAmount < standardPrice) {
    let diffVoucher = 0
    let promoUnitVoucher = `€`

    if (voucher.type === `percent`) {
      diffVoucher = (standardPrice * voucher.amount) / 100
      promoUnitVoucher = `%`
    } else {
      diffVoucher = voucher.amount
    }

    const priceVoucher = standardPrice - diffVoucher
    if (priceVoucher >= PERCENT_MINI_TO_APPLY_VOUCHER * standardPrice) {
      promoValue = voucher.amount
      promoUnit = promoUnitVoucher
      diff = diffVoucher
      price = standardPrice - diff
      betterPrice = diff > 0
      voucherApplied = true
    }
  }

  return {
    betterPrice,
    price,
    diff,
    standardPrice,
    offerPrice,
    promoValue,
    promoUnit,
    voucherApplied,
    dateToApply: voucherApplied ? voucher.endValidityDate : product.impactProduct.variants[0].campaignEndDate,
    annualPurchaseOnly: price < PRICE_MIN_TO_ACCEPT_ALL_PAYMENTS,
  }
}

export function usePrice (product) {
  const { voucher } = useCampaignContext()

  return useMemo(() => getPrice(product, voucher), [product, voucher])
}

export function usePrices (products) {
  const { voucher } = useCampaignContext()

  return useMemo(() => {
    return products.map(product => getPrice(product, voucher))
  }, [products, voucher])
}

// Sort by price using voucher if exists
// You have to pass campaignProducts in order to sort using offer code
export function useSortProductsByPrice (products, order = `asc`) {
  const { voucher } = useCampaignContext()

  return useMemo(() => {
    const sortValue = order === `asc` ? -1 : 1

    return [...products]
      .map(product => ({
        product,
        price: getPrice(product, voucher).price || 0,
      }))
      .sort((a, b) => {
        if (a.price < b.price) {
          return sortValue
        }

        if (a.price > b.price) {
          return !sortValue
        }

        return 0
      })
      .map(({ product }) => product)
  }, [products, voucher, order])
}

export function useHousingTypeGroups (products, mixteGroup = true) {
  return useMemo(() => {
    const groupedProducts = products.reduce(
      (acc, product) => {
        acc[product.housingType].push(product)

        return acc
      },
      {
        [HOUSINGTYPE_APPARTEMENT]: [],
        [HOUSINGTYPE_MAISON]: [],
        [HOUSINGTYPE_MIXTE]: [],
      },
    )

    if (!mixteGroup) {
      // prettier-ignore
      return {
        [HOUSINGTYPE_APPARTEMENT]: groupedProducts[HOUSINGTYPE_APPARTEMENT].concat(groupedProducts[HOUSINGTYPE_MIXTE]),
        [HOUSINGTYPE_MAISON]: groupedProducts[HOUSINGTYPE_MAISON].concat(groupedProducts[HOUSINGTYPE_MIXTE]),
      }
    }

    return groupedProducts
  }, [products, mixteGroup])
}

const VARIANT_OTHER = 1
const VARIANT_EMPLOYEE_30_PERCENT = 2
const VARIANT_EMPLOYEE_1_EURO = 3

function getProductVariantType (campaignProduct) {
  const { offerCode } = campaignProduct ? campaignProduct.impactProduct.variants[0] : {}

  if (offerCode.startsWith(`H`)) {
    if (offerCode.endsWith(`C`)) {
      return VARIANT_EMPLOYEE_30_PERCENT
    }

    if (offerCode.endsWith(`C1`)) {
      return VARIANT_EMPLOYEE_1_EURO
    }
  }

  return VARIANT_OTHER
}

function betterPriceSentence (
  t,
  offerPrice,
  standardPrice,
  diff,
  campaignEndDate,
  endPriceValidityDate,
  variantType,
  brandName,
) {
  if (variantType === VARIANT_EMPLOYEE_30_PERCENT || variantType === VARIANT_EMPLOYEE_1_EURO) {
    const lastSentence = t(`common:product.price_mention_employee`, { brandName })

    if (variantType === VARIANT_EMPLOYEE_30_PERCENT) {
      const percent = formatPrice((diff * 100) / standardPrice)

      return t(`common:product.price_mention_employee_percent`, { percent }) + ` ` + lastSentence
    }

    const amount = formatPrice(offerPrice)

    return t(`common:product.price_mention_employee_amount`, { amount }) + ` ` + lastSentence
  }

  return t(`common:product.price_mention`, {
    price: formatPrice(offerPrice),
    date: campaignEndDate ? formatDatetime(campaignEndDate) : endPriceValidityDate,
    standardPrice: formatPrice(standardPrice),
    endPriceValidityDate,
  })
}

function firstYearSentence (t, standardPrice, endPriceValidityDate) {
  return t(`common:product.price_mention_first_year`, {
    standardPrice: formatPrice(standardPrice),
    endPriceValidityDate,
  })
}

export function usePriceMention (campaignProduct) {
  const { t } = useTranslation()
  const { betterPrice, price, standardPrice, diff, dateToApply } = usePrice(campaignProduct)
  const brandName = useBrandName()
  const endPriceValidityDate = useEndPriceValidityDateHook()

  if (!campaignProduct) {
    return null
  }

  if (betterPrice) {
    return betterPriceSentence(
      t,
      price,
      standardPrice,
      diff,
      dateToApply,
      endPriceValidityDate,
      getProductVariantType(campaignProduct),
      brandName,
    )
  }

  return firstYearSentence(t, standardPrice, endPriceValidityDate)
}

export function useUniverseSlugGroups (products) {
  return useMemo(() => {
    const groupedProducts = {}

    for (const product of products) {
      for (const { slug } of product.universes) {
        if (!groupedProducts[slug]) {
          groupedProducts[slug] = []
        }

        groupedProducts[slug].push(product)
      }
    }

    return groupedProducts
  }, [products])
}

export function usePriceText (product) {
  const { t } = useTranslation()
  const { voucher } = useCampaignContext()

  const { annualPurchaseOnly, price } = useMemo(() => getPrice(product, voucher), [product, voucher])

  if (annualPurchaseOnly) {
    return t(`common:product.price_by_year`, { price: formatPriceAnnually(price) })
  }

  return t(`common:product.price_by_month`, { price: formatPriceMonthly(price) })
}

export function useProductDocumentUrl (productId, field) {
  const { allProduct } = useStaticQuery(graphql`
    query {
      allProduct {
        nodes {
          originalId
          ipidSheet {
            publicURL
          }
          cguSheet {
            publicURL
          }
          commercialSheet {
            publicURL
          }
          paymentSheet {
            publicURL
          }
        }
      }
    }
  `)

  const mapping = useMemo(() => {
    return allProduct.nodes.reduce((acc, product) => {
      acc[product.originalId] = {
        ipidSheet: product.ipidSheet.publicURL,
        cguSheet: product.cguSheet.publicURL,
        commercialSheet: product.commercialSheet?.publicURL ?? ``,
        paymentSheet: product.paymentSheet.publicURL,
      }

      return acc
    }, {})
  }, [allProduct])

  return mapping[productId]?.[field] ?? ``
}
