import loadable from "@loadable/component"

export const StickyContainer = loadable(() => import(`react-sticky`), {
  ssr: true,
  resolveComponent: module => module.StickyContainer,
  fallback: ({ children }) => children,
})

export const Sticky = loadable(() => import(`react-sticky`), {
  ssr: true,
  resolveComponent: module => module.Sticky,
  fallback: ({ children }) => children({ style: null, distanceFromTop: 0, isSticky: false }),
})
