import { graphql, useStaticQuery } from "gatsby"
import { usePageContext } from "../navigation/pageContext.provider"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useSessionStorage } from "@homeserve/react-storage-provider"
import _noop from "lodash/noop"
import { isMultilingualCoBrandVariant } from "./coBrand.utils"

const CoBrandContext = createContext({
  coBrand: null,
  coBrandReady: true,
  activateSessionCoBrand: _noop,
})

export default function CoBrandProvider ({ children }) {
  if (!APP_CONFIG.featureFlags.co_brand) {
    return children
  }

  const { coBrandOriginalId = ``, coBrandable = false, pageContextReady } = usePageContext()

  const sessionStorage = useSessionStorage()
  const { allCoBrand } = useStaticQuery(query)

  const { getCoBrandByOriginalId, getCoBrandByBrandCode } = useMemo(
    () => ({
      getCoBrandByOriginalId: originalId => {
        const foundCoBrand = originalId && allCoBrand.nodes.find(coBrandNode => coBrandNode.originalId === originalId)

        return foundCoBrand || null
      },
      getCoBrandByBrandCode: (brandCode, brandLocale) => {
        const foundCoBrand =
          brandCode &&
          allCoBrand.nodes.find(
            coBrandNode =>
              coBrandNode.brandCode === brandCode && (!brandLocale || coBrandNode.brandLocale === brandLocale),
          )

        return foundCoBrand || null
      },
    }),
    [allCoBrand],
  )

  // Load coBrand server side on a coBrand page
  const [coBrand, setCoBrand] = useState(() => getCoBrandByOriginalId(coBrandOriginalId))
  const [coBrandReady, setCoBrandReady] = useState(false)

  useEffect(() => {
    setCoBrandReady(false)
  }, [coBrandable, coBrandOriginalId])

  useEffect(() => {
    if (coBrandReady || !sessionStorage.isReady) {
      return
    }

    if (coBrandOriginalId) {
      // if we have a cobrand from context
      const coBrandPage = getCoBrandByOriginalId(coBrandOriginalId)

      if (coBrandPage) {
        sessionStorage.write(`coBrand`, coBrandOriginalId)
      }

      setCoBrand(coBrandPage)
    } else if (coBrandable) {
      // if page is cobrandable and a cobrand is in storage
      const sessionCoBrandId = sessionStorage.read(`coBrand`)
      const coBrandSession = getCoBrandByOriginalId(sessionCoBrandId)

      setCoBrand(coBrandSession)
    } else {
      sessionStorage.remove(`coBrand`)
      setCoBrand(null)
    }
    setCoBrandReady(true)
  }, [coBrandReady, sessionStorage.isReady, sessionStorage.read(`coBrand`)])

  const providedValue = useMemo(
    () => ({
      coBrand: coBrandOriginalId ? getCoBrandByOriginalId(coBrandOriginalId) : coBrand,
      coBrandReady: !!coBrandOriginalId || (coBrandReady && pageContextReady),
      activateSessionCoBrand: variant => {
        if (!coBrandable) {
          return
        }
        const brandCode = variant.areaCode.slice(0, 2)
        const brandLocale = isMultilingualCoBrandVariant(variant)
          ? `${variant.offerCode.slice(-2).toLowerCase()}_${APP_CONFIG.country.toUpperCase()}`
          : ``

        const coBrandFromCode = getCoBrandByBrandCode(brandCode, brandLocale)

        if (coBrandFromCode) {
          sessionStorage.write(`coBrand`, coBrandFromCode.originalId)
          setCoBrand(coBrandFromCode)
        }

        setCoBrandReady(true)
      },
    }),
    [coBrandable, coBrand, coBrandReady, pageContextReady, coBrandOriginalId],
  )

  return <CoBrandContext.Provider value={providedValue}>{children}</CoBrandContext.Provider>
}

CoBrandProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useCoBrand () {
  return useContext(CoBrandContext)
}

const query = graphql`
  query {
    allCoBrand {
      nodes {
        originalId
        footerBgColor
        footerColor
        productPictoColor
        headerBorderBottomColor
        buttonSecondaryColor
        buttonPrimaryBgColor
        productListItemDescBgColor
        productListItemPriceColor
        productListItemTopBgColor
        productListTitleBgColor
        warrantiesColor
        wcbCode
        wcbColor
        wcbBgColor
        slug
        brandCode
        brandLocale
        supportPhone
        salesPhone
        campaignPhone
        brandName
        coBrandHome {
          bannerBgColor
          active
        }
        logo {
          alt
          title
          file {
            publicURL
            childImageSharp {
              fixed(height: 55) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        coBrandHome {
          active
          # Because of graphql we have query a field, not an object. However what we want to know here is if coBrandHome is null or not
        }
        logoResponsive {
          alt
          title
          file {
            publicURL
            childImageSharp {
              fixed(height: 50) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
