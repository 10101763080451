import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { slug }) => t(`pages:campaign.path`, { campaignSlug: slug })),
}

if (SERVER) {
  const { createFaqTagRegex } = require(`../navigation.utils`)

  route.createPages = ({ isMultiLingual }, { allCampaign }) => {
    const getI18n = require(`../../translation/i18n`).default
    const { t } = getI18n()

    return allCampaign.nodes.map(node => {
      const {
        id,
        originalId,
        landingPageProducts,
        breadcrumb,
        metaDescription,
        metaTitle,
        type,
        disablePhone,
        disableChat,
        disableWcb,
      } = node
      const isSimpleCampaign = landingPageProducts.length === 1

      const {
        impactProduct: { impactId, underwriterVerbose },
      } = landingPageProducts[0]

      const extraContext = isSimpleCampaign
        ? {
            tagRegex: createFaqTagRegex([
              [`qui-peut-souscrire`, impactId],
              [`comment-souscrire`],
              [`conditions-du-contrat`, impactId],
              [`conditions-de-service`, APP_CONFIG.faqTag],
              [`qui-sommes-nous`, APP_CONFIG.faqTag, `${APP_CONFIG.faqTag}-${underwriterVerbose}`],
            ]),
          }
        : {}

      return {
        path: route.createPath(isMultiLingual, t, node),
        component: `./src/modules/campaign/${isSimpleCampaign ? `simple` : `multiple`}Campaign.page.js`,
        redirectFrom: `/campagne/${originalId}/`,
        context: {
          id,
          originalId,
          type,
          disablePhone,
          disableChat,
          disableWcb,
          indexed: false,
          simplifiedNavigation: true,
          meta: {
            title: metaTitle,
            description: metaDescription,
          },
          breadcrumb: [{ label: breadcrumb }],
          preventSticky: isSimpleCampaign,
          ...extraContext,
        },
      }
    })
  }

  route.query = `
       allCampaign(filter: {coBrand: {id: {eq: null}}}) {
        nodes {
          id
          type
          disablePhone
          disableChat
          disableWcb
          originalId
          slug
          breadcrumb
          metaTitle
          metaDescription
          landingPageProducts {
            id
            impactProduct {
              impactId
              underwriterVerbose
            }
          }
        }
      }
    `
}

export default route
