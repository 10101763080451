import React from "react"
import PropTypes from "prop-types"
import { LocalStorageProvider, SessionStorageProvider, CookieStorageProvider } from "@homeserve/react-storage-provider"
import CampaignProvider from "../campaign/campaign.provider"
import PaymentProvider from "../payment/payment.provider"
import ThirdPartiesProvider from "../thirdParties/thirdParties.provider"
import PageContextProvider from "../navigation/pageContext.provider"
import UserProvider from "../navigation/user.provider"
import LocationProvider from "../navigation/location.provider"
import CoBrandProvider from "../coBrand/coBrand.provider"
import ThemeProvider from "./theme.provider"
import OverlayProvider from "./overlay.provider"
import { globalHistory } from "@reach/router"
import { QueryParamProvider } from "use-query-params"

export default function Provider ({ pageContext, children: layout, location }) {
  return (
    <LocalStorageProvider storageKeys={APP_CONFIG.storage.localStorageKeys}>
      <SessionStorageProvider storageKeys={APP_CONFIG.storage.sessionStorageKeys}>
        <CookieStorageProvider storageKeys={APP_CONFIG.storage.cookieStorageKeys}>
          <LocationProvider location={location}>
            <QueryParamProvider location={location} reachHistory={globalHistory}>
              <PageContextProvider context={pageContext}>
                <CoBrandProvider>
                  <ThemeProvider theme={APP_CONFIG.theme}>
                    <ThirdPartiesProvider>
                      <PaymentProvider>
                        <CampaignProvider>
                          <UserProvider>
                            <OverlayProvider>{layout}</OverlayProvider>
                          </UserProvider>
                        </CampaignProvider>
                      </PaymentProvider>
                    </ThirdPartiesProvider>
                  </ThemeProvider>
                </CoBrandProvider>
              </PageContextProvider>
            </QueryParamProvider>
          </LocationProvider>
        </CookieStorageProvider>
      </SessionStorageProvider>
    </LocalStorageProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.any.isRequired,
  pageContext: PropTypes.object,
  location: PropTypes.object.isRequired,
}
