import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath(t => t(`pages:contactConfirmation.path`)),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual, locales }) => {
    const getI18n = require(`../../translation/i18n`).default

    return locales.map(locale => {
      const { t } = getI18n(locale)

      return {
        path: route.createPath(isMultiLingual, t, { locale }),
        component: `./src/modules/contact/contactConfirmation.page.js`,
        context: {
          locale,
          preventHomeBreadcrumb: true,
          indexed: false,
          meta: {
            title: t(`pages:contactConfirmation.title`),
            description: t(`pages:contactConfirmation.description`),
          },
        },
      }
    })
  }
}

export default route
