import { fetchApiCore, fetchJSON } from "@homeserve/api-fetcher"
import { saveAs } from "file-saver"
import { sentryCaptureException } from "../modules/thirdParties/sentry"

export const fetchCore = (...params) =>
  fetchApiCore({
    url: APP_CONFIG.apis.core.url,
    env: APP_CONFIG.apis.core.env,
    token: APP_CONFIG.apis.core.tokenClient,
  })(...params)

async function normalizeSiteError (apiCall) {
  try {
    return await apiCall
  } catch (fetchApiError) {
    const errorBody = fetchApiError.response.body

    // Malformed api-coreback error
    if (!errorBody || typeof errorBody !== `object` || Array.isArray(errorBody)) {
      fetchApiError.response.body = {
        code: `unknown_error`,
        detail: `Malformed error`,
      }
    } else if (!errorBody.code) {
      // core_back may forward api_core errors...
      const maybeApiCoreError = typeof errorBody.errors === `object` && errorBody.errors.code

      fetchApiError.response.body = {
        code: maybeApiCoreError ? errorBody.errors.code : `unknown_error`,
        detail: maybeApiCoreError ? errorBody.errors.codeVerbose : `Malformed error`,
      }
    }

    throw fetchApiError
  }
}
export const fetchSite = async (uri, params = {}) => {
  return normalizeSiteError(
    fetchJSON(APP_CONFIG.apis.site.url + uri, {
      ...params,
      headers: {
        ...(params.headers || null),
        Authorization: `Bearer ${APP_CONFIG.apis.site.tokenClient}`,
      },
    }),
  )
}

export const fetchServerSite = (uri, params = {}) => {
  return normalizeSiteError(
    fetchJSON(APP_CONFIG.apis.site.url + uri, {
      ...params,
      headers: {
        ...(params.headers || null),
        Authorization: `Bearer ${APP_CONFIG.apis.site.tokenServer}`,
      },
    }),
  )
}

export function createCaptchaHeader (captchaToken) {
  return {
    "x-captcha-token": captchaToken,
  }
}

export function downloadFile (urlOrBlob, name) {
  return new Promise((resolve, reject) => {
    // This is a blob
    if (typeof urlOrBlob !== `string`) {
      saveAs(urlOrBlob, name)
      resolve()

      return
    }

    // Due to a bug with Firefox and service worker
    // We transform file content into blob
    const xhr = new window.XMLHttpRequest()
    xhr.open(`GET`, urlOrBlob)
    xhr.responseType = `blob`
    xhr.onload = function () {
      saveAs(xhr.response, name)
      resolve()
    }
    xhr.onerror = function () {
      const error = new Error(`Download file ${urlOrBlob} failed.`)
      sentryCaptureException(error)
      reject(error)
    }
    xhr.send()
  })
}
