import React, { createContext, useEffect, useContext, useCallback, useMemo, useReducer } from "react"
import PropTypes from "prop-types"
import { postOfferCodeStrategy } from "./campaign.api"
import { useLocalStorage } from "@homeserve/react-storage-provider"
import { usePageContext } from "../navigation/pageContext.provider"
import { productSlugNormalizer } from "../product/product.api"
import { usePaymentContext } from "../payment/payment.provider"
import { addCodePromoTracking } from "../thirdParties/gtm/tagManager"
import { searchVoucher } from "../voucher/voucher.api"
import InvalidVoucherError from "../voucher/invalidVoucherError.class"
import { useQueryParams, StringParam } from "use-query-params"
import { useTranslation } from "../translation/translate.component"
import useNavigation from "../navigation/useNavigation"

const CampaignContext = createContext()

export function useCampaignContext () {
  return useContext(CampaignContext)
}

const voucherMapKey = {
  REDEMPTIONS_EXCEEDED: `REDEMPTIONS_EXCEEDED`,
  EXPIRED: `EXPIRED`,
  DEFAULT_ERROR: `DEFAULT_ERROR`,
}

const voucherMapError = {
  [voucherMapKey.REDEMPTIONS_EXCEEDED]: `common:voucher.error_redemptions_exceeded`,
  [voucherMapKey.EXPIRED]: `common:voucher.error_expired`,
  [voucherMapKey.DEFAULT_ERROR]: `common:voucher.error_default`,
}

const initialState = {
  ready: false,
  voucher: null,
  offerCode: ``,
  origin: `url`,
  campaigns: [],
  products: [],
  errorRedirectLink: ``,
  errorOfferCode: false,
  errorMessage: ``,
}

function reducer (state, action) {
  switch (action.type) {
    case `LOADING`:
      return {
        ...state,
        ready: false,
      }

    case `CLEAN_PREVIOUS_ERRORS`:
      return {
        ...state,
        errorRedirectLink: ``,
        errorOfferCode: false,
      }

    case `CAMPAIGN_REDIRECT_LINK`:
    case `PRODUCT_REDIRECT_LINK`:
      return {
        ...state,
        ready: true,
        voucher: null,
        offerCode: ``,
        origin: action.origin,
        campaigns: [],
        products: [],
        errorOfferCode: false,
        errorRedirectLink: action.link,
      }

    case `APPLY_OFFER_CODE`:
      return {
        ...state,
        ready: true,
        voucher: null,
        offerCode: action.offerCode,
        origin: action.origin,
        campaigns: action.campaigns,
        products: action.products,
        errorOfferCode: false,
        errorRedirectLink: ``,
      }

    case `APPLY_VOUCHER`:
      return {
        ...state,
        ready: true,
        voucher: action.voucher,
        offerCode: ``,
        origin: action.origin,
        campaigns: [],
        products: [],
        errorOfferCode: false,
        errorRedirectLink: ``,
      }

    case `ERROR_OFFER_CODE`:
      return {
        ...state,
        ready: true,
        origin: action.origin,
        campaigns: [],
        products: [],
        errorOfferCode: true,
        errorMessage: action.message || ``,
        errorRedirectLink: ``,
      }

    case `DONE`:
      return {
        ...state,
        ready: true,
      }
  }
}

const CAMPAIGN_QUERY_PARAMS = {
  voucher: StringParam,
  code: StringParam,
}

export default function CampaignProvider ({ children }) {
  const { t } = useTranslation()
  const [state, dispatch] = useReducer(reducer, initialState)

  const localStorageState = useLocalStorage()
  const { route, originalId, pageId } = usePageContext()
  const { product, isReady: paymentContextIsReady } = usePaymentContext()
  const [queryParams] = useQueryParams(CAMPAIGN_QUERY_PARAMS)
  const { navigate, resolveRoute } = useNavigation()

  const handleOfferCode = async (
    offerCode,
    origin,
    _route,
    _originalId,
    _product,
    _localStorageState,
    fallbackMessage = ``,
  ) => {
    dispatch({ type: `CLEAN_PREVIOUS_ERRORS` })

    const pageType = _route
    const strategyBody = {
      offerCode,
      fallbackLink: origin !== `storage`,
    }

    switch (pageType) {
      case `campaign`:
        strategyBody.landingPageId = _originalId
        break

      case `product`:
        strategyBody.productId = _originalId
        break

      case `paymentStep1`:
      case `paymentStep2`:
      case `paymentStep3`:
      case `paymentStep4`:
        if (_product) {
          strategyBody.productId = _product.id
        } else {
          dispatch({ type: `DONE` })
        }
        break

      case `home`:
        break

      default:
        // Other page types are never handled, nothing to do
        dispatch({ type: `DONE` })

        return
    }

    if (origin === `storage` && pageType === `home`) {
      return
    }

    try {
      const { operation, products, landingPages: campaigns } = await postOfferCodeStrategy(strategyBody)

      _localStorageState.remove(`voucher`)
      _localStorageState.write(`offerCode`, offerCode)

      switch (operation) {
        case `link`: {
          if (pageType === `home`) {
            if (campaigns.length > 0) {
              navigate(`campaign`, campaigns[0])
            } else {
              navigate(`product`, { ...products[0], slug: productSlugNormalizer(products[0].slug) })
            }
            // We leave right away and let the redirection occurs

            return
          }
          if (campaigns.length > 0) {
            dispatch({
              type: `CAMPAIGN_REDIRECT_LINK`,
              origin,
              link: resolveRoute(`campaign`, campaigns[0]),
            })
          } else {
            dispatch({
              type: `PRODUCT_REDIRECT_LINK`,
              origin,
              link: resolveRoute(`product`, { slug: productSlugNormalizer(products[0].slug) }),
            })
          }
          break
        }

        case `apply`: {
          dispatch({
            type: `APPLY_OFFER_CODE`,
            origin,
            products,
            campaigns,
            offerCode,
          })
          break
        }
      }
    } catch (error) {
      if (origin === `storage`) {
        _localStorageState.remove(`offerCode`)
      }
      dispatch({ type: `ERROR_OFFER_CODE`, origin, message: fallbackMessage })
    }

    if (window.closeOfferCodeMask) {
      window.closeOfferCodeMask()
    }
  }

  const dispatchVoucherError = (message, origin) => {
    dispatch({
      type: `ERROR_OFFER_CODE`,
      origin,
      message,
    })

    return message
  }

  function handleVoucherError (voucher, origin) {
    if (voucher.validityErrorCodes && Array.isArray(voucher.validityErrorCodes)) {
      const codeError = voucher.validityErrorCodes[0] || voucherMapKey.DEFAULT_ERROR
      if (codeError in voucherMapError) {
        return dispatchVoucherError(t(voucherMapError[codeError]), origin)
      }
    }

    return dispatchVoucherError(t(voucherMapError.DEFAULT_ERROR), origin)
  }

  const handleVoucher = useCallback(
    (code, origin) => {
      if (!APP_CONFIG.featureFlags.voucher) {
        return Promise.reject(new Error(`Voucher is deactivated`))
      }

      dispatch({ type: `CLEAN_PREVIOUS_ERRORS` })

      return searchVoucher(code)
        .then(voucher => {
          if (voucher.isValid) {
            localStorageState.remove(`offerCode`)
            localStorageState.write(`voucher`, code)
            dispatch({
              type: `APPLY_VOUCHER`,
              voucher,
              origin,
            })
          } else {
            if (origin === `storage`) {
              localStorageState.remove(`voucher`)
            }
            throw new InvalidVoucherError(handleVoucherError(voucher, origin))
          }
        })
        .catch(error => {
          if (origin === `storage`) {
            localStorageState.remove(`voucher`)
          }
          throw error
        })
    },
    [localStorageState],
  )

  const resolveCode = useCallback(
    function (queryKey, storageKey, origin, inputCode = ``) {
      let code

      switch (origin) {
        case `url`:
          code = queryParams[queryKey]
          break
        case `storage`:
          code = localStorageState.read(storageKey)
          break
        default:
          code = inputCode
      }

      return code
    },
    [localStorageState, queryParams],
  )

  const applyVoucherFromOrigin = async (origin, inputCode) => {
    if (!APP_CONFIG.featureFlags.voucher) {
      return false
    }

    const voucherCode = resolveCode(`voucher`, `voucher`, origin, inputCode)

    if (voucherCode) {
      if (state.voucher && voucherCode === state.voucher.code) {
        return true
      }

      // eslint-disable-next-line no-useless-catch
      try {
        await handleVoucher(voucherCode, origin)

        return true
      } catch (error) {}
    }

    return false
  }

  const applyOfferCodeFromOrigin = (origin, inputCode) => {
    const offerCode = resolveCode(`code`, `offerCode`, origin, inputCode)

    if (offerCode) {
      handleOfferCode(offerCode, origin, route, originalId, product, localStorageState)

      return true
    }

    return false
  }

  useEffect(() => {
    if (paymentContextIsReady) {
      dispatch({ type: `LOADING` })

      applyVoucherFromOrigin(`url`)
        .then(hasApplied => hasApplied || applyOfferCodeFromOrigin(`url`))
        .then(hasApplied => hasApplied || applyVoucherFromOrigin(`storage`))
        .then(hasApplied => hasApplied || applyOfferCodeFromOrigin(`storage`))
        .then(_ => {
          dispatch({ type: `DONE` })
        })
    }
  }, [paymentContextIsReady, pageId, state.voucher])

  useEffect(() => {
    if (state.errorOfferCode && state.errorMessage && state.origin === `url`) {
      import(`../information/toaster.component`).then(({ toastMessage: toast }) => {
        const options = { type: toast.TYPE.WARNING, toastId: `toaster-voucher` }
        toast(state.errorMessage, options)
      })
    }
  }, [state.errorMessage])

  const inputOfferCode = promoCode => {
    if (promoCode) {
      dispatch({ type: `LOADING` })

      handleVoucher(promoCode, `input`).catch(errorVoucher => {
        // if the errorVoucher is a typeError, the voucher does not exists (cf line 303)
        // if the errorVoucher is an Error, the voucher exists but is not valid, and we need to keep it's error message
        // (cf line 296)
        handleOfferCode(
          promoCode,
          `input`,
          route,
          originalId,
          product,
          localStorageState,
          errorVoucher instanceof InvalidVoucherError ? errorVoucher.message : ``,
        )
      })
    }
  }

  useEffect(() => {
    if (state.ready) {
      addCodePromoTracking(state)
    }
  }, [state.ready])

  const providedValue = useMemo(
    () => ({
      inputOfferCode,
      ...state,
    }),
    [inputOfferCode, state],
  )

  return <CampaignContext.Provider value={providedValue}>{children}</CampaignContext.Provider>
}

CampaignProvider.propTypes = {
  children: PropTypes.any,
}
