import React, { useContext, createContext, useEffect, useReducer, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import ScriptLoader from "./scriptLoader.component"
import { createConfig } from "./thirdParties.utils"
import { usePageContext } from "../navigation/pageContext.provider"

const ThirdPartiesContext = createContext()
const CONFIG = createConfig()
const STATUS_INACTIVE = -1
const STATUS_IS_LOADING = 0
const STATUS_IS_WAITING = 1
const STATUS_LOADED = 2
const attributes = { async: true }

const initialState = {
  isGRPDReady: false,
  thirdParties: Object.keys(CONFIG).reduce((state, thirdParty) => {
    state[thirdParty] = {
      status: STATUS_INACTIVE,
      data: null,
    }

    return state
  }, {}),
}

function updateThirdParty (state, thirdParty, statusData) {
  return {
    ...state,
    thirdParties: {
      ...state.thirdParties,
      [thirdParty]: statusData,
    },
  }
}

function reducer (state, action) {
  switch (action.type) {
    case `LOADING`:
      if (state.thirdParties[action.thirdParty].status === STATUS_INACTIVE) {
        return updateThirdParty(state, action.thirdParty, {
          status: STATUS_IS_LOADING,
          data: null,
        })
      }
      break

    case `LOADED`:
      if (state.thirdParties[action.thirdParty].status === STATUS_IS_LOADING) {
        return updateThirdParty(state, action.thirdParty, {
          status: STATUS_LOADED,
          data: action.data || null,
        })
      }
      break

    case `UNLOAD`:
      if (state.thirdParties[action.thirdParty].status !== STATUS_INACTIVE) {
        return updateThirdParty(state, action.thirdParty, {
          status: STATUS_INACTIVE,
          data: null,
        })
      }
      break

    case `GRPD_READY`: {
      const newState = {
        ...state,
        isGRPDReady: true,
      }

      for (const thirdParty in state.thirdParties) {
        const thirdPartyState = state.thirdParties[thirdParty]

        if (thirdPartyState.status === STATUS_IS_WAITING) {
          thirdPartyState.status = STATUS_IS_LOADING
          thirdPartyState.data = null
        }
      }

      return newState
    }
  }

  return state
}

function isValidThirdParty (thirdParty) {
  if (CONFIG[thirdParty]) {
    return true
  }

  if (!process.env.OPTIMIZED) {
    throw new Error(`The thirdParty ${thirdParty} is not declared in the config.`)
  }

  return false
}

export default function ThirdPartiesProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { locale: currentLocale } = usePageContext()
  const locale = useRef()
  locale.current = currentLocale

  const { setGRPDReady, load, unload } = useMemo(
    () => ({
      setGRPDReady: () =>
        dispatch({
          type: `GRPD_READY`,
        }),
      load: thirdParty => {
        if (isValidThirdParty(thirdParty)) {
          dispatch({
            type: `LOADING`,
            thirdParty,
          })
        }
      },
      unload: thirdParty => {
        if (isValidThirdParty(thirdParty)) {
          dispatch({
            type: `UNLOAD`,
            thirdParty,
          })
        }
      },
    }),
    [],
  )

  const { isLoaded, isLoading, getData } = useMemo(
    () => ({
      isLoaded: thirdParty => state.thirdParties[thirdParty].status === STATUS_LOADED,
      isLoading: thirdParty => state.thirdParties[thirdParty].status === STATUS_IS_LOADING,
      getData: thirdParty => state.thirdParties[thirdParty].data,
    }),
    [state],
  )

  const scripts = useMemo(
    () =>
      Object.keys(state.thirdParties)
        .filter(thirdParty => state.thirdParties[thirdParty].status !== STATUS_INACTIVE && state.isGRPDReady)
        .map(thirdParty => {
          const config = CONFIG[thirdParty]

          return {
            key: thirdParty,
            url: config.scriptUrl,
            onCreate () {
              if (config.onBeforeLoad) {
                config.onBeforeLoad(
                  data =>
                    dispatch({
                      type: `LOADED`,
                      thirdParty,
                      data,
                    }),
                  locale.current,
                )
              }
            },
            onLoad () {
              if (config.onLoad) {
                config.onLoad(() => {
                  dispatch({
                    type: `LOADED`,
                    thirdParty,
                  })
                })
              } else if (!config.onBeforeLoad) {
                dispatch({
                  type: `LOADED`,
                  thirdParty,
                })
              }
            },
          }
        }),
    [state],
  )

  const provided = useMemo(
    () => ({
      load,
      unload,
      setGRPDReady,
      isLoaded,
      isLoading,
      getData,
    }),
    [isLoaded, isLoading, getData],
  )

  useEffect(() => {
    load(`gtm`)
    load(`abtasty`)
  }, [])

  return (
    <ThirdPartiesContext.Provider value={provided}>
      {children}
      {scripts.map(({ key, url, onCreate, onLoad }) => (
        <ScriptLoader key={key} url={url} onCreate={onCreate} onLoad={onLoad} attributes={attributes} />
      ))}
    </ThirdPartiesContext.Provider>
  )
}

export function useThirdPartiesContext () {
  return useContext(ThirdPartiesContext)
}

export function useThirdParty (thirdParty) {
  const { load, unload } = useContext(ThirdPartiesContext)

  useEffect(() => {
    load(thirdParty)

    return () => unload(thirdParty)
  }, [])
}

ThirdPartiesProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
