import React, { useEffect, useMemo, useState } from "react"
import { useTranslation as useTranslationI18next, Trans as TransI18next } from "react-i18next"

const tags = [
  `span`,
  `a`,
  `button`,
  `label`,
  `input`,
  `textarea`,
  `p`,
  `h1`,
  `h2`,
  `h3`,
  `h4`,
  `h5`,
  `img`,
  `li`,
  `div`,
]

function getContent (element) {
  let text

  if (typeof element === `string`) {
    text = element
  } else {
    switch (element.nodeName) {
      case `IMG`:
        text = element.alt
        break

      case `INPUT`:
      case `TEXTAREA`:
        text = element.placeholder
        break

      default:
        text = element.textContent
    }
  }

  if (!text) {
    return ``
  }

  return text
    .replace(/<[^>]*>/g, ``)
    .replace(/[:*]/g, ``)
    .toLowerCase()
    .trim()
}

function getElementByContent (searchText) {
  const foundElements = []

  for (const tag of tags) {
    const elements = document.getElementsByTagName(tag)
    const safeSearchText = getContent(searchText)

    // eslint-disable-next-line no-labels
    loopElements: for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      const elementText = getContent(element)

      if (!elementText) {
        continue
      }

      for (let j = foundElements.length - 1; j >= 0; j--) {
        const foundElement = foundElements[j]

        if (getContent(foundElement) === elementText) {
          if (element.contains(foundElement)) {
            // eslint-disable-next-line no-labels
            continue loopElements
          }

          if (foundElement.contains(element)) {
            foundElements.splice(j, 1)
            removeTooltipsByElement(foundElement)
            break
          }
        }
      }

      if (
        // window.getComputedStyle(element).display !== `none` &&
        !/gatsby/.test(element.id) &&
        safeSearchText === elementText
      ) {
        foundElements.push(element)
      }
    }
  }

  return foundElements
}

function showTooltip (element, contextId, key, searchText) {
  let tooltip
  const id = `tooltip-i18n-${contextId}-${key}`

  element.style.border = `2px solid blue`

  element.onmouseover = function () {
    const { top, left, height, width } = this.getBoundingClientRect()

    tooltip = tooltip || document.createElement(`mark`)
    tooltip.id = id
    tooltip.style.zIndex = 999
    tooltip.style.display = `block`
    tooltip.style.position = `absolute`
    tooltip.style.color = `white`
    tooltip.style.backgroundColor = `blue`
    tooltip.style.top = Number(top + height + window.scrollY) + `px`
    tooltip.style.left = Number(left + window.scrollX) + `px`
    tooltip.style.minWidth = width + `px`
    tooltip.textContent = `KEY: ${key}\nVALUE: ${searchText}`
    tooltip.sourceElement = this
    document.body.appendChild(tooltip)
    document.body.style.position = `relative`
  }

  element.onmouseout = function () {
    if (!tooltip) {
      tooltip = document.getElementById(id)
    }

    if (tooltip) {
      tooltip.style.display = `none`
    }
  }
}

function removeTooltip (tooltip) {
  tooltip.sourceElement.onmouseout()
  delete tooltip.sourceElement.onmouseover
  delete tooltip.sourceElement.onmouseout
  tooltip.parentNode.removeChild(tooltip)
}

function removeTooltipsById (id) {
  document.querySelectorAll(`[id^=tooltip-i18n-${id}]`).forEach(removeTooltip)
}

function removeTooltipsByElement (element) {
  document.querySelectorAll(`[id^=tooltip-i18n]`).forEach(tooltip => {
    if (tooltip.sourceElement === element) {
      removeTooltip(tooltip)
    }
  })

  delete element.style.border
}

function updateTooltip (id, key, searchText) {
  window.requestAnimationFrame(() => {
    const elements = getElementByContent(searchText)

    if (!elements.length) {
      return
    }

    for (const element of elements) {
      showTooltip(element, id, key, searchText)
    }
  })
}

function useTranslationCustom () {
  const { t } = useTranslationI18next()
  const id = useMemo(
    () =>
      Math.random()
        .toString(36)
        .substr(2, 9),
    [],
  )
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => removeTooltipsById(id)
  }, [])

  return {
    t: (key, values) => {
      const value = t(key, values)

      if (mounted) {
        updateTooltip(id, key, value)
        setTimeout(() => {
          updateTooltip(id, key, value)
        }, 1000)
      }

      return value
    },
  }
}

// eslint-disable-next-line react/prop-types
function TransCustom ({ t, ...props }) {
  const id = useMemo(
    () =>
      Math.random()
        .toString(36)
        .substr(2, 9),
    [],
  )
  const [mounted, setMounted] = useState(false)

  const newT = (key, values) => {
    const value = t(key, values)

    if (mounted) {
      updateTooltip(id, key, value)
      setTimeout(() => {
        updateTooltip(id, key, value)
      }, 1000)
    }

    return value
  }

  useEffect(() => {
    setMounted(true)

    return () => removeTooltipsById(id)
  }, [])

  return <TransI18next t={newT} {...props} />
}

export const useTranslation = APP_CONFIG.featureFlags.debugTranslation ? useTranslationCustom : useTranslationI18next

export const Trans = APP_CONFIG.featureFlags.debugTranslation ? TransCustom : TransI18next
