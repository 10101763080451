import React from "react"
import styled from "styled-components"
import { colors, fontSizes } from "../structure/theme"
import { Bold, flexCenter } from "../structure/theme/styles.component"
import propTypes from "prop-types"
import usePhoneCostNumber from "./phoneCostNumber.hook"

const Cartouche = styled.div`
  display: flex;
  background-color: white;
  border: 1px solid ${colors(`grey.g800`)};
  width: 240px;
  height: 35px;
  color: ${colors(`grey.g800`)};
  font-size: ${fontSizes(`regular`)};
`

const Free = styled.div`
  width: 190px;
  margin-right: 8px;
  height: 34px;
  font-weight: 700;
  background-color: ${colors(`grey.g800`)};
  color: white;
  font-size: ${fontSizes(`x_small`)};
  line-height: ${fontSizes(`x_small`)};
  text-align: center;
`

const Arrow = styled.div`
  margin-top: 21px;
  height: 24px;
  width: 20px;
  background-color: white;
  transform: translateY(-50%) rotate(45deg);
`

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`

const Wrapper = styled.div`
  ${flexCenter};
  width: 100%;
`

const Text = styled.div`
  display: flex;
  margin-top: 4px;
`

export default function PhoneBox ({ phoneNumber }) {
  const phoneCostNumber = usePhoneCostNumber()

  return (
    <Cartouche>
      <Wrapper>
        <Anchor href={`tel:${phoneNumber}`} data-testid="box_phone">
          <Bold>{phoneNumber}</Bold>
        </Anchor>
      </Wrapper>

      {phoneCostNumber && (
        <>
          <Arrow />
          <Free>
            <Text>
              <Bold fontWeight={300}>{phoneCostNumber}</Bold>
            </Text>
          </Free>
        </>
      )}
    </Cartouche>
  )
}

PhoneBox.propTypes = {
  phoneNumber: propTypes.string.isRequired,
}
