import { formatHousingType, transformRedirectProduct, usePrice } from "../product/product.utils"
import { useCampaignProduct } from "../campaign/campaign.utils"
import { useCampaignContext } from "../campaign/campaign.provider"
import { useMemo } from "react"

export function mapUniverseToPaymentUniverse ({ name, slug }) {
  return {
    name,
    slug,
  }
}

export function formatFromBasketToLocalstorage ({
  // step 1
  housingType,
  postalCode,
  city,
  address,
  residence,
  principalHousing,
  propertyType,
  propertyType2,
  waterMeterPosition,
  // step2
  email,
  civility,
  firstname,
  lastname,
  phone,
  differentBilling,
  billingPostalCode,
  billingCity,
  billingAddress,
  billingResidence,
  emailOptin,
  // sessionId
  sessionId,
  // product
  item: {
    coverit: {
      id,
      name,
      slug,
      universes,
      housingType: housingTypeProduct,
      sprf,
      tunnelSpecificity,
      blacklistedDepartments,
      impactProduct: { underwriterId, impactId, name: impactProductName },
      ownerRedirectProduct,
      occupantRedirectProduct,
      renterRedirectProduct,
      otherRedirectProduct,
    },
    coveritVariant,
  },
}) {
  return {
    product: {
      id,
      name,
      slug,
      housingType: housingTypeProduct,
      sprf,
      tunnelSpecificity,
      universes: universes.map(mapUniverseToPaymentUniverse),
      blacklistedDepartments,
      impactProduct: {
        underwriterId,
        impactId,
        name: impactProductName,
        variants: [coveritVariant],
      },
      ownerRedirectProduct: transformRedirectProduct(ownerRedirectProduct),
      occupantRedirectProduct: transformRedirectProduct(occupantRedirectProduct),
      renterRedirectProduct: transformRedirectProduct(renterRedirectProduct),
      otherRedirectProduct: transformRedirectProduct(otherRedirectProduct),
    },
    step1: {
      housingType: formatHousingType(housingType),
      postalCode,
      city,
      address,
      residence,
      principalHousing,
      propertyType,
      propertyType2,
      waterMeterPosition,
    },
    step2: {
      email,
      civility,
      firstname,
      lastname,
      phone,
      differentBilling,
      billingPostalCode,
      billingCity,
      billingAddress,
      billingResidence,
      emailOptin,
    },
    sessionId,
  }
}

export function usePaymentErrorData (product) {
  const [campaignProduct, , offerCodeApplied] = useCampaignProduct(product)
  const { voucher, offerCode } = useCampaignContext()
  const { price, voucherApplied } = usePrice(campaignProduct)

  return useMemo(() => {
    if (!campaignProduct) {
      return {}
    }

    return {
      productId: campaignProduct.id,
      productName: campaignProduct.name,
      productVariant: campaignProduct.impactProduct.variants[0],
      productPrice: price,
      voucher: voucherApplied ? voucher.code : ``,
      offerCode: offerCodeApplied && !voucherApplied ? offerCode : ``,
    }
  }, [campaignProduct, offerCode, voucher, voucherApplied])
}
