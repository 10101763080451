import React from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import loadable from "@loadable/component"
import { modules, useThemeContext } from "../../structure/theme"
import { useUserContext } from "../user.provider"
import Section from "../../../components/section.component"
import PreFooter from "./preFooter.component"

const FullFooter = loadable(() => import(`./fullFooter.component`))
const SimplifiedFooter = loadable(() => import(`./simplifiedFooter.component`))

const Wrapper = styled.div`
  display: flex;
  color: ${modules(`navigation.footer.color`)};
  padding: 20px 10px 50px;

  ${breakpoint(`medium`)`
    padding-bottom: 50px;
  `}
`

const Space = styled.div`
  margin-top: 50px;
`

export default function Footer () {
  const { isSimplifiedNavigation } = useUserContext()
  const theme = useThemeContext()

  const Component = isSimplifiedNavigation || process.env.PARTNER === `hsvbe` ? SimplifiedFooter : FullFooter

  return (
    <>
      <Space />
      <PreFooter />
      <Section
        data-testid="footer"
        as="footer"
        bgWidth="full"
        bgColor={theme.modules.navigation.footer.backgroundColor}
      >
        <Wrapper>
          <Component />
        </Wrapper>
      </Section>
    </>
  )
}
