import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath(t => t(`pages:results.path`)),
}

if (SERVER) {
  const { t } = require(`../../translation/i18n`).default()

  route.createPages = ({ isMultiLingual }) => ({
    path: route.createPath(isMultiLingual, t),
    component: `./src/modules/search/results.page.js`,
    context: {
      indexed: false,
      breadcrumb: [{ label: `Résultats` }],
      meta: {
        title: t(`pages:results.title`),
        description: t(`pages:results.description`),
      },
    },
  })
}

export default route
