import { useContext, useEffect, useState } from "react"
import { ThemeContext, css } from "styled-components"
import _get from "lodash/get"
import { useWindowResize } from "@homeserve/react-hooks"

export function useThemeContext () {
  return useContext(ThemeContext)
}

/**
 * condition function is a sugar syntactic for styled-components
 *
 * Usage:
 *
 *  The first argument is a component prop to be truthy in order to apply some styles
 *
 *  1) With one argument, the function required styles in backtick
 *
 *  ${condition(`isInline`)`
 *    display: inline;
 *  `}
 *
 *  Equivalent to:
 *
 *  ${props => props.isInline && `
 *    display: inline;
 *  `}
 *
 *
 *  2) With two arguments, the 2nd is the style to apply
 *
 *  display: ${condition(`isInline`, `inline`)};
 *
 *  Equivalent to:
 *
 *  display: ${props => props.isInline && `inline`};
 *
 *
 *  3) With three arguments, the 2nd argument is the style to apply if the prop is truthy otherwise the 3rd argument
 *
 *  display: ${condition(`isInline`, `inline`, `block`)};
 *
 *  Equivalent to:
 *
 *  display: ${props => props.isInline ? `inline` : `block`};
 *
 */
export function condition (prop, value, valueOtherwise) {
  if (valueOtherwise) {
    return props => (props[prop] ? value : valueOtherwise)
  }

  if (value) {
    return props => props[prop] && value
  }

  return (...styles) => props => props[prop] && css(...styles)
}

export function notCondition (prop, value, valueOtherwise) {
  if (valueOtherwise) {
    return props => (!props[prop] ? value : valueOtherwise)
  }

  if (value) {
    return props => !props[prop] && value
  }

  return (...styles) => props => !props[prop] && css(...styles)
}

/**
 * colors, fontSizes, ..., functions are sugar syntactics for styled-components to extract theme styles
 *
 * Usage of colors:
 *
 *
 *  1) With one argument,
 *     the function return the theme value
 *
 *  color: ${colors(`primary`)};
 *
 *  Equivalent to:
 *
 *  color: ${props => props.theme.colors.primary};
 *
 *
 *  2) With two arguments,
 *     if the prop (1st argument) is truthy then the color (2nd argument) is extracted from the theme
 *
 *  color: ${colors(`disabled`, `grey.g300`)};
 *
 *  Equivalent to:
 *
 *  color: ${props => props.disabled && props.theme.colors.grey.g300};
 *
 *
 *  3) With two arguments, if 2nd argument is a function then it uses it as a transformation (example with polished)
 *
 *  color: ${colors(`primary`, darken(0.15))};
 *
 *  Equivalent to:
 *
 *  color: ${props => darken(0.15, props.theme.colors.primary)};
 *
 *
 *  3) With three arguments,
 *     if the prop (1st argument) is truthy then the color (2nd argument) is extracted from the theme
 *     otherwise the 3rd is used
 *
 *  display: ${colors(`disabled`, `grey.g300`, `primary`)};
 *
 *  Equivalent to:
 *
 *  display: ${props => props.disabled ? props.theme.colors.grey.g300 : props.theme.colors.primary};
 *
 */
const conditionTheme = keyTheme => (propOrValue, value, valueOtherwise) => {
  if (valueOtherwise) {
    return props =>
      props[propOrValue]
        ? _get(props.theme, `${keyTheme}.${value}`)
        : _get(props.theme, `${keyTheme}.${valueOtherwise}`)
  }

  if (value) {
    if (typeof value === `function`) {
      return props => value(_get(props.theme, `${keyTheme}.${propOrValue}`))
    }

    return props => props[propOrValue] && _get(props.theme, `${keyTheme}.${value}`)
  }

  return props => _get(props.theme, `${keyTheme}.${propOrValue}`)
}

export const colors = conditionTheme(`colors`)
export const fontSizes = conditionTheme(`fonts.sizes`)
export const maxWidth = conditionTheme(`maxWidth`)
export const modules = conditionTheme(`modules`)

export const percent = value => `${Math.round(value * 100 * 100) / 100}%`
export const toPx = value => (isNaN(value) ? value : `${value}px`)

export function useBreakpoint () {
  const [state, setState] = useState(`large`)

  // We cannot rely on window.innerWidth for this because it depends on overflow hidden css
  // ie: elements bigger than the screen will extend innerWidth
  //
  // clientWidth ensures we are using the device width
  // useWindowResize is still here to detect window width changes
  const { width: innerWidth } = useWindowResize()

  useEffect(() => {
    const width = document.documentElement.clientWidth

    switch (true) {
      case width > 0 && width < APP_CONFIG.theme.breakpoints.small:
        setState(`small`)
        break
      case width > APP_CONFIG.theme.breakpoints.small && width < APP_CONFIG.theme.breakpoints.medium:
        setState(`medium`)
        break
      case width > APP_CONFIG.theme.breakpoints.medium && width < APP_CONFIG.theme.breakpoints.large:
        setState(`large`)
        break
      default:
        setState(`x_large`)
        break
    }
  }, [innerWidth])

  return state
}
