import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { slug }) =>
    t(`pages:coBrand.path`, {
      coBrandSlug: slug,
      slug: `emptypage`,
    }),
  ),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual }, data) => {
    const getI18n = require(`../../translation/i18n`).default
    const { t } = getI18n()

    return data.allCoBrand.nodes.map(node => ({
      path: route.createPath(isMultiLingual, t, node),
      component: `./src/modules/coBrand/empty.page.js`,
      context: {
        coBrandOriginalId: node.originalId,
        indexed: false,
        meta: {
          title: node.brandName,
          description: `description`,
        },
      },
    }))
  }

  route.query = `
      allCoBrand {
        nodes {
          originalId
          slug
        }
      }
    `
}

export default route
