import { useEffect, useMemo, useState } from "react"
import { useCampaignContext } from "./campaign.provider"
import useNavigation from "../navigation/useNavigation"

export function findCampaignProduct (campaign, product) {
  if (!campaign || !product) {
    return null
  }

  const productId = product.originalId || product.id
  const landingPageProducts = campaign.landingPageProducts || []

  for (const landingPageProduct of landingPageProducts) {
    if (landingPageProduct.product.id === productId) {
      return landingPageProduct.product
    }
  }

  return null
}

export function useCampaignProduct (product) {
  const { products, campaigns, ready } = useCampaignContext()
  const [internalReady, setInternalReady] = useState(false)
  const { resolveRoute } = useNavigation()

  useEffect(() => {
    setInternalReady(true)
  }, [ready])

  return useMemo(() => {
    if (product) {
      let offerProduct = products.find(({ id }) => id === (product.originalId || product.id))

      if (offerProduct) {
        return [
          {
            ...product,
            impactProduct: {
              ...product.impactProduct,
              variants: offerProduct.impactProduct.variants,
            },
          },
          ready && internalReady,
          true,
        ]
      }

      let campaignUri

      for (const campaign of campaigns) {
        offerProduct = findCampaignProduct(campaign, product)

        // override uri only if a product matched a LPS
        // pretty unstable for now because if we want to use the product uri inside a LPS
        if (offerProduct && campaign.landingPageProducts.length === 1) {
          campaignUri = resolveRoute(`campaign`, campaign)
          break
        }
      }

      if (offerProduct) {
        return [
          {
            ...product,
            ...(campaignUri ? { overrideUrl: campaignUri } : {}),
            impactProduct: {
              ...product.impactProduct,
              variants: offerProduct.impactProduct.variants,
            },
          },
          ready && internalReady,
          true,
        ]
      }
    }

    return [product, ready && internalReady, false]
  }, [campaigns, products, product, ready && internalReady])
}
