import { tokenize } from "../../utils/format"
import { localeToLang } from "../translation/translate.utils"

function tokenizePath (path, ...args) {
  return tokenize(path || `/`, ...args)
}

export function createSelfCareLink (path, utmCampaign = null, extraTokens = null) {
  if (!APP_CONFIG.featureFlags.selfcare) {
    return ``
  }

  return (
    APP_CONFIG.selfcare.baseUrl +
    tokenizePath(path, {
      utm_source: APP_CONFIG.selfcare.utm_source,
      utm_campaign: utmCampaign,
      ...extraTokens,
    })
  )
}

export function createParrainageLink (path, utmMedium, utmCampaign = null, extraTokens = null) {
  return (
    APP_CONFIG.parrainage.baseUrl +
    tokenizePath(path, {
      utm_source: APP_CONFIG.parrainage.utm_source,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      ...extraTokens,
    })
  )
}

export function createLesBonsTuyauxLink (path, utmCampaign) {
  return (
    APP_CONFIG.lesbonstuyaux.baseUrl +
    tokenizePath(path, {
      utm_source: APP_CONFIG.lesbonstuyaux.utm_source,
      utm_campaign: utmCampaign,
    })
  )
}

export function createHomeServeLink (path) {
  return APP_CONFIG.homeserve.baseUrl + tokenizePath(path)
}

export function createFaqTagRegex (groupTags, separator = `&&`) {
  if (CLIENT) {
    return ``
  }

  const regexPerGroup = groupTags.map(tags => {
    if (tags.length === 1) {
      return tags[0]
    }

    return tags.map(tag => `(?=.*(${separator}|^)${tag}(${separator}|$))`).join(``)
  })

  return `/(${regexPerGroup.join(`|`)})/`
}

export function scrollTop (top) {
  window.scrollTo(0, top)
}

export function createMultiLingualPath (createPath) {
  return (isMultiLingual = false, t = undefined, params = {}) => {
    const routePath = createPath(t, params)

    if (isMultiLingual) {
      return `/${localeToLang(params.locale || APP_CONFIG.defaultLocale)}` + routePath
    }

    return routePath
  }
}
