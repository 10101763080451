import { fetchServerSite, fetchSite } from "../../utils/fetch"

export function getServerCampaigns () {
  return fetchServerSite(`/landing_pages/active`).then((campaigns = []) => campaigns.map(campaignNormalizer))
}

function campaignNormalizer (campaign) {
  if (!campaign) {
    return null
  }

  const { landingPageProducts } = campaign

  return {
    ...campaign,
    landingPageProducts: landingPageProducts || [],
  }
}

export function postOfferCodeStrategy (body) {
  return fetchSite(`/product_display/offer_code`, {
    method: `POST`,
    body: JSON.stringify(body),
  })
}
