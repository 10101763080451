import React, { memo } from "react"
import Section from "../../../components/section.component"
import styled from "styled-components"
import Proptypes from "prop-types"
import loadable from "@loadable/component"
import { responsiveHamburger } from "../menu/responsiveHamburger"
import { colors, fontSizes, modules, useThemeContext } from "../../structure/theme"
import { useCoBrand } from "../../coBrand/coBrand.provider"
import { usePageContext } from "../pageContext.provider"
import { useUserContext } from "../user.provider"

const PostHeader = loadable(() => import(`./postHeader.component`))
const PreHeader = loadable(() => import(`./preHeader.component`))
const CobrandHeader = loadable(() => import(`../../coBrand/theme/cobrandHeader.component`))
const PartnerHeader = loadable(() => import(`./partnerHeader.component`))

const StickyHeader = styled.div`
  background-color: ${props => props.color || `white`};
  border-style: solid;
  border-color: ${colors(`grey.g600`)};
  border-width: ${props => (props.isSticky ? `0 0 1px 0` : 0)};
  font-size: ${fontSizes(`regular`)};
  display: flex;
  flex-direction: column;

  ${responsiveHamburger`
    transition: background box-shadow 0.3s;
    background-color: ${props => props.bgColor || `white`};
    border-color: ${modules(`navigation.header.borderBottomColor`)};
    border-width: 0 0 ${props => (props.withMargin ? APP_CONFIG.theme.modules.navigation.header.marginBottom : 0)} 0;
  `}
`

function Header ({ isSticky }) {
  const { coBrand } = useCoBrand()
  const { hideSalesPhone } = usePageContext()
  const { context, usePhoneNumber } = useUserContext()
  const theme = useThemeContext()
  const phoneNumber = usePhoneNumber()
  const { disablePhone } = context

  return (
    <StickyHeader
      sticky={isSticky}
      data-testid={isSticky ? `sticky_header` : `top_header`}
      withMargin={theme.modules.navigation.header.displayMargin || coBrand}
    >
      {!disablePhone && APP_CONFIG.featureFlags.preHeader && <PreHeader coBrand={coBrand} phoneNumber={phoneNumber} />}
      <Section>
        {coBrand ? (
          <CobrandHeader coBrand={coBrand} phoneNumber={phoneNumber} />
        ) : (
          <PartnerHeader isSticky={isSticky} phoneNumber={phoneNumber} />
        )}
      </Section>
      {!disablePhone && !hideSalesPhone && APP_CONFIG.featureFlags.postHeader && phoneNumber && (
        <PostHeader phoneNumber={phoneNumber} />
      )}
    </StickyHeader>
  )
}

Header.propTypes = {
  isSticky: Proptypes.bool.isRequired,
}

export default memo(Header)
