import { FetchApiError } from "@homeserve/api-fetcher"

export function sentryInit () {
  if (process.env.OPTIMIZED || process.env.ENVIRONMENT === `mock`) {
    const Sentry = (window.Sentry = require(`@sentry/browser`))
    const release = `${process.env.VERSION}${process.env.ENVIRONMENT === `preprod` ? `-preprod` : ``}`

    Sentry.init({
      dsn: APP_CONFIG.sentry.dsn,
      release,
      environment: process.env.ENVIRONMENT,
    })
  }
}

export function sentryCaptureException (error, data = null) {
  if (!window.Sentry) {
    return
  }

  let exception = ``
  const scopeExtra = { ...data }

  if (error instanceof FetchApiError) {
    exception = error
    scopeExtra.request = error.request
    scopeExtra.response = error.response
  } else if (error instanceof Error) {
    exception = error
  } else {
    const errorMessage = String(error.message || error)
    exception = new Error(errorMessage)
  }

  window.Sentry.withScope(scope => {
    for (const extra in scopeExtra) {
      scope.setExtra(extra, scopeExtra[extra])
    }

    window.Sentry.captureException(exception)
  })
}
