// fr_FR => fr
export function localeToLang (locale) {
  return locale.substring(0, 2)
}

// fr_FR => fr-FR
export function localeToWebLocale (locale) {
  return locale.replace(`_`, `-`)
}

// fr-FR => fr_FR
export function webLocaleToLocale (locale) {
  return locale.replace(`-`, `_`)
}

export function getLocalesPathFromLocale (locale, namespace) {
  return `/locales/${locale}/${namespace}.json`
}
