const REGEX_OFFER_CODE_AGENT = /LANG[A-Z]{2}AGENT$/
const REGEX_OFFER_CODE_LANG = /LANG[A-Z]{2}$/

export function isCoBrandAgentVariant (variant) {
  return REGEX_OFFER_CODE_AGENT.test(variant.offerCode)
}

export function isMultilingualCoBrandVariant (variant) {
  return REGEX_OFFER_CODE_LANG.test(variant.offerCode)
}
