import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

// pour les liens cliquables rouges soulignés
export const Redirect = styled.span`
  color: ${props => props.color || props.theme.colors.primary};
  text-decoration: underline;
  a {
    color: ${props => props.color || props.theme.colors.primary};
    text-decoration: underline;
  }
`

export const Underlined = styled.span`
  text-decoration: underline;
`

export const Bold = styled.span`
  font-weight: ${props => props.fontWeight || 900};
`

export const Emphasis = styled.span`
  font-style: italic;
`

export const Strikethrough = styled.span`
  text-decoration: line-through;
`
export const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const fullWidthMobile = breakpoint(`xsmall`, `medium`)`
  margin-left: -30px;
  position: relative;
  left: 15px;
`

export const Spacer = styled.div`
  flex: 1;
`
