import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, { coBrand, slug }) =>
    t(`pages:coBrandLps.path`, { coBrandSlug: coBrand.slug, campaignSlug: slug }),
  ),
}

if (SERVER) {
  const { createFaqTagRegex } = require(`../navigation.utils`)

  route.createPages = ({ isMultiLingual }, { allCampaign }) => {
    const getI18n = require(`../../translation/i18n`).default

    return allCampaign.nodes.map(node => {
      const {
        id,
        originalId,
        landingPageProducts,
        coBrand,
        metaTitle,
        metaDescription,
        type,
        disablePhone,
        disableChat,
        disableWcb,
      } = node
      const {
        impactProduct: { impactId, underwriterVerbose },
      } = landingPageProducts[0]
      const locale = node.coBrand.brandLocale
      const { t } = getI18n(locale)
      const faqLocaleTag = isMultiLingual ? [locale] : []

      return {
        path: route.createPath(isMultiLingual, t, { ...node, locale }),
        redirectFrom: `/campagne/${originalId}/`,
        component: `./src/modules/campaign/simpleCampaign.page.js`,
        context: {
          id,
          locale,
          indexed: false,
          coBrandOriginalId: coBrand.originalId,
          type,
          disablePhone,
          disableChat,
          disableWcb,
          tagRegex: createFaqTagRegex([
            [`qui-peut-souscrire`, impactId, ...faqLocaleTag],
            [`comment-souscrire`, ...faqLocaleTag],
            [`conditions-du-contrat`, impactId, ...faqLocaleTag],
            [`conditions-de-service`, APP_CONFIG.faqTag, ...faqLocaleTag],
            [`qui-sommes-nous`, APP_CONFIG.faqTag, `${APP_CONFIG.faqTag}-${underwriterVerbose}`, ...faqLocaleTag],
          ]),
          meta: {
            title: metaTitle,
            description: metaDescription,
          },
          preventHomeBreadcrumb: true,
        },
      }
    })
  }

  route.query = `
      allCampaign(filter: {coBrand: {id: {ne: null}}}) {
        nodes {
          id
          originalId
          slug
          type
          disablePhone
          disableChat
          disableWcb
          metaTitle
          metaDescription
          landingPageProducts {
            id
            impactProduct {
              impactId
              underwriterVerbose
            }
          }
          coBrand {
            originalId
            slug
            brandLocale
          }
        }
      }
    `
}

export default route
