import styled from "styled-components"
import { percent } from "../../modules/structure/theme"

const FrenchFlag = styled.span`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;

  :before,
  :after {
    content: "";
    position: absolute;
    top: 0;
    width: ${percent(1 / 3)};
    height: 100%;
  }

  :before {
    /* stylelint-disable-next-line color-no-hex */
    background-color: #3150a3;
    left: 0;
  }

  :after {
    /* stylelint-disable-next-line color-no-hex */
    background-color: #ed2224;
    right: 0;
  }
`

export default FrenchFlag
