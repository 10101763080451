import React from "react"
import Layout from "./default.layout"
import { ThemeProvider } from "styled-components"

// eslint-disable-next-line react/prop-types
export default ({ element, props }) => {
  if (
    // eslint-disable-next-line react/prop-types
    props.location.pathname === `${APP_CONFIG.basePath}/offline-plugin-app-shell-fallback/`
  ) {
    return element
  }

  // eslint-disable-next-line react/prop-types
  if (props.pageContext.preventLayout) {
    return <ThemeProvider theme={APP_CONFIG.theme}>{element}</ThemeProvider>
  }

  return <Layout {...props}>{element}</Layout>
}
