import React from "react"
import styled from "styled-components"
import { condition, fontSizes } from "../../structure/theme"
import { Image } from "../../../components/images.component"
import FrenchFlag from "../../../components/pictos/frenchFlag.component"
import PhoneBox from "../../contact/phoneBox.component"
import { graphql, useStaticQuery } from "gatsby"
import breakpoint from "styled-components-breakpoint"
import EscdaLazy from "../../landing/escda/escdaLazy.component"
import { useTranslation } from "../../translation/translate.component"
import { useUserContext } from "../user.provider"
import Section from "../../../components/section.component"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  ${breakpoint(`medium`)`
      flex-direction: row;
      align-items: center;
  `}
`

const Col = styled.div`
  flex: 1;
  display: flex;
  align-items: ${condition(`top`, `flex-start`, `center`)};
  margin-bottom: 10px;

  ${breakpoint(`medium`)`
    margin-bottom: 0;
    justify-content: ${condition(`center`, `center`, `left`)};
  `}
`

const HsvListen = styled.span`
  font-weight: 900;
`

const EscdaContent = styled.span`
  font-size: ${fontSizes(`small`)};
  margin-left: 25px;
`

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Border = styled.div`
  border-top: 1px solid ${props => props.theme.colors.grey.g500};
`

export default function PreFooter () {
  const { isSimplifiedNavigation } = useUserContext()
  const { fevad } = useStaticQuery(query)
  const { t } = useTranslation()
  const { usePhoneNumber } = useUserContext()
  const phoneNumber = usePhoneNumber()

  if (!isSimplifiedNavigation) {
    return null
  }

  return (
    <>
      <Border />
      <Section data-testid="pre_footer" bgWidth="full">
        <Row>
          <Col center={!phoneNumber}>
            <FrenchFlag />
            <HsvListen>{t(`common:navigation.pre_footer`)}</HsvListen>
          </Col>
          {phoneNumber && (
            <>
              <Col center>
                <PhoneBox phoneNumber={phoneNumber} />
              </Col>
              <Col>{t(`common:navigation.footer_schedule`)}</Col>
            </>
          )}
        </Row>
      </Section>
      <Section bgWidth="full" bgColor="#EEE6C9">
        <Row>
          <EscdaLazy Wrapper={Col} center={false} withMargin={false}>
            <EscdaContent>
              {t(`common:escda.footer`)}
              {` `}
              <ExternalLink target="_blank" rel="noreferrer" href="https://www.escda.fr/">
                escda.fr
              </ExternalLink>
              .
            </EscdaContent>
          </EscdaLazy>
          <Col top>
            <Image
              file={fevad}
              alt={t(`common:escda.image_alt`)}
              style={{
                width: `55px`,
                height: `60px`,
              }}
            />
            <EscdaContent>{t(`common:navigation.pre_footer_fevad`)}</EscdaContent>
          </Col>
        </Row>
      </Section>
    </>
  )
}

const query = graphql`
  query {
    fevad: file(name: { eq: "fevad" }) {
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

PreFooter.propTypes = {}
