import React, { memo } from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { tokenizeObject } from "../../utils/format"

function SeoSchema ({ name, ...props }) {
  const data = tokenizeObject(APP_CONFIG.seo.schema[name], props)
  data[`@context`] = `http://schema.org/`
  const seoJsonLd = JSON.stringify(data, null, 2)

  return (
    <Helmet>
      <script type="application/ld+json" data-testid={name}>
        {seoJsonLd}
      </script>
    </Helmet>
  )
}

SeoSchema.propTypes = {
  name: PropTypes.string.isRequired,
}

export default memo(SeoSchema)
