import { navigate as gatsbyNavigate } from "gatsby"
import { useTranslation } from "../translation/translate.component"
import { useMemo } from "react"
import { routes } from "./routes"
import queryString from "query-string"
import { useIsMultiLingual } from "../website/locales.hook"
import { usePageContext } from "./pageContext.provider"

function resolveRouteWithTranslation (isMultiLingual, t, locale, route, params = null, queryParams = null) {
  const getRoute = routes[route]

  if (!process.env.OPTIMIZED && !getRoute) {
    throw new Error(`Route name ${route} does not exist`)
  }

  const url = (params && params.overrideUrl) || getRoute.createPath(isMultiLingual, t, { locale, ...params })

  if (queryParams) {
    return `${url}?${queryString.stringify(queryParams)}`
  }

  return url
}

function resolveRouteToAbsolute (resolveRoute, ...params) {
  return APP_CONFIG.baseUrl + resolveRoute(...params)
}

function redirect (resolveRoute, ...params) {
  return gatsbyNavigate(resolveRoute(...params), { replace: true })
}

function navigate (resolveRoute, ...params) {
  const options = params[1] || {}

  return gatsbyNavigate(resolveRoute(...params), options)
}

export default function useNavigation () {
  const { t } = useTranslation()
  const isMultiLingual = useIsMultiLingual()
  const { locale } = usePageContext()

  return useMemo(() => {
    const resolveRoute = resolveRouteWithTranslation.bind(null, isMultiLingual, t, locale)

    return {
      resolveRoute,
      resolveRouteToAbsolute: resolveRouteToAbsolute.bind(null, resolveRoute),
      navigate: navigate.bind(null, resolveRoute),
      redirect: redirect.bind(null, resolveRoute),
    }
  }, [t, isMultiLingual, locale])
}
