import { createMultiLingualPath } from "../navigation.utils"
import { routes } from "../routes"

const route = {
  createPath: createMultiLingualPath(() => `/`),
}

if (SERVER) {
  const getI18n = require(`../../translation/i18n`).default

  route.query = `
    allWebsiteHomepage {
      nodes {
        id
      }
    }
    allLpmEneco: allCoBrandCampaign (filter: {coBrand: {brandCode: {eq: "EN"}}}) {
      nodes {
        coBrand {
          slug
          brandLocale
        }
        originalId
        slug
      }
    }
  `

  route.createPages = ({ isMultiLingual, locales }, { allWebsiteHomepage, allLpmEneco }) => {
    return locales.map(locale => {
      const { t } = getI18n(locale)
      const websiteHomepage = allWebsiteHomepage.nodes[0]

      let title = t(`pages:home.title`)
      let description = t(`pages:home.description`)
      let lpmEnecoLink = ``

      // Ugly until coreback api add meta fields on homepage
      switch (process.env.PARTNER) {
        case `hsv`:
          title = `HomeServe: contrats d'assistance pour les urgences de la maison`
          description = `Découvrez l'ensemble de nos contrats d'assistance dédiés aux problèmes de plomberie, d'électricité, d'électroménager, de gaz... HomeServe, expert des solutions d’assistance.`
          break
        case `hsvbe`: {
          title = t(`pages:home.title_hsvbe`)
          description = t(`pages:home.description_hsvbe`)

          const lpmEneco = allLpmEneco.nodes.filter(
            lpmEnecoNode =>
              lpmEnecoNode.coBrand.brandLocale === locale &&
              APP_CONFIG.homeLpmEnecoId.includes(lpmEnecoNode.originalId),
          )[0]

          if (lpmEneco) {
            lpmEnecoLink = routes.coBrandLpm.createPath(isMultiLingual, t, {
              coBrand: lpmEneco.coBrand,
              slug: lpmEneco.slug,
              locale,
            })
          }
          break
        }
      }

      return {
        path: route.createPath(isMultiLingual, t, { locale }),
        component: `./src/modules/landing/home.page.js`,
        context: {
          id: websiteHomepage.id,
          locale,
          meta: {
            title,
            description,
          },
          preventHomeBreadcrumb: true,
          translatable: true,
          ...(lpmEnecoLink ? { lpmEnecoLink } : null),
        },
      }
    })
  }
}

export default route
