import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { usePageContext } from "../navigation/pageContext.provider"

const OverlayContext = createContext()

const initialState = {
  bottomComponent: null,
}

export default function OverlayProvider ({ children }) {
  const [state, setState] = useState(() => initialState)
  const { pageContextReady } = usePageContext()

  useEffect(() => {
    if (!pageContextReady) {
      setState(initialState)
    }
  }, [pageContextReady])

  const providedValue = useMemo(
    () => ({
      state,
      setState,
    }),
    [state],
  )

  return <OverlayContext.Provider value={providedValue}>{children}</OverlayContext.Provider>
}

export function useOverlayAddBottomComponent (createBottomComponent, dependencies = []) {
  const { setState } = useContext(OverlayContext)

  useEffect(() => {
    setState(state => ({
      ...state,
      bottomComponent: createBottomComponent(),
    }))
  }, dependencies)
}

export function useOverlayGetBottomComponent () {
  const { state } = useContext(OverlayContext)

  return state.bottomComponent
}

OverlayProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
