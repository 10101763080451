import React, { memo } from "react"
import Img from "gatsby-image/withIEPolyfill"
import Proptypes from "prop-types"
import styled from "styled-components"

function ImageSharp ({ file, alt: defaultAlt = ``, title: defaultTitle = ``, ...otherProps }) {
  if (!file) {
    return null
  }
  const { childImageSharp, publicURL = `` } = file.file || file
  const { fluid = null, fixed = null } = childImageSharp || {}

  if (fluid || fixed) {
    const props = {
      ...otherProps,
      alt: file.alt || defaultAlt,
      title: file.title || defaultTitle,
      fluid,
      fixed,
    }

    return <Img {...props} />
  }

  if (publicURL && publicURL.endsWith(`.svg`)) {
    return <img {...otherProps} src={publicURL} alt={file.alt || defaultAlt} title={file.title || defaultTitle} />
  }

  return null
}

ImageSharp.propTypes = {
  // File or RemoteImage types
  file: Proptypes.object.isRequired,
  alt: Proptypes.string,
  title: Proptypes.string,
}

// For adjusting width, wrap the Image with Flex and its width props
export const Image = styled(memo(ImageSharp))`
  width: 100%;
`

// We can't use styled-components here, otherwise the style will be applied to the <img/> generated
// instead of gatsby-image-wrapper
const absoluteImageStyle = {
  position: `absolute`,
  left: 0,
  top: 0,
  width: `100%`,
  height: `100%`,
  minHeight: `200px`,
}
export function BgImage (props) {
  return <ImageSharp {...props} style={absoluteImageStyle} />
}

export const Svg = styled.svg.attrs({ preserveAspectRatio: `xMinYMid meet` })`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
