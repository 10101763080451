import { fetchCore } from "../../utils/fetch"

function filterVoucher (voucher) {
  return {
    code: voucher.code,
    type: voucher.type,
    amount: voucher.amount,
    endValidityDate: voucher.endValidityDate,
    minCartAmount: voucher.minCartAmount,
    isValid: voucher.isValid,
    redemptionsUsage: voucher.redemptionsUsage,
    redemptionsAllowed: voucher.redemptionsAllowed,
    validityErrorCodes: voucher.validityErrorCodes || [],
  }
}

export function searchVoucher (code, fetchParams) {
  return fetchCore(`/services/vouchers/v1/vouchers/${code}/`, fetchParams).then(filterVoucher)
}
