import React, { useMemo } from "react"
import styled from "styled-components"
import { condition, maxWidth } from "../../structure/theme"
import { Image } from "../../../components/images.component"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "../../translation/translate.component"

const EscdaContainer = styled.div`
  display: flex;
  justify-content: ${condition(`center`, `center`, `left`)};
  align-items: center;
  flex-direction: row;
  ${props =>
    props.withMargin &&
    `
    margin: 10px 0;
  `}

  ${props =>
    props.block &&
    `
    max-width: ${maxWidth(`medium`)}px;
    ${props.withMargin ? `margin: 10px auto auto;` : ``}
    width: 100%;
  `}
`
export const ImageSize = { MEDIUM: `medium`, BIG: `big` }
const { MEDIUM, BIG } = ImageSize

export default function Escda ({
  Wrapper = React.Fragment,
  block = true,
  withMargin = true,
  center = true,
  children = null,
  size = MEDIUM,
}) {
  const { t } = useTranslation()
  const { serviceClient } = useStaticQuery(query)

  const style = useMemo(() => {
    return size === BIG
      ? {
          height: `92px`,
          width: `85px`,
        }
      : {
          height: `60px`,
          width: `55px`,
        }
  }, [size])

  return (
    <Wrapper>
      <EscdaContainer
        block={block}
        center={center}
        withMargin={withMargin}
        data-testid={arguments[0][`data-testid`] || `escda`}
      >
        <div>
          <Image file={serviceClient} alt={t(`escda.image_alt`)} style={style} />
        </div>
        {children}
      </EscdaContainer>
    </Wrapper>
  )
}

Escda.propTypes = {
  Wrapper: PropTypes.elementType,
  children: PropTypes.node,
  block: PropTypes.bool,
  center: PropTypes.bool,
  withMargin: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(ImageSize)),
}

const query = graphql`
  query {
    serviceClient: file(name: { eq: "elu-service-client" }) {
      childImageSharp {
        fixed(width: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
