const route = {
  createPath: () => APP_CONFIG.oldBrowserUrl,
}

if (SERVER) {
  route.createPages = () => ({
    path: route.createPath(),
    component: `./src/modules/landing/oldBrowser.page.js`,
    context: {
      preventLayout: true,
      indexed: false,
    },
  })
}

export default route
