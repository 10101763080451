import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Link from "../link.component"
import { colors, condition, notCondition } from "../../structure/theme"

const ChildList = styled.ul`
  position: absolute;
  display: none;
  padding: 16px;
  background-color: ${colors(`primary`)};
  &:hover {
    display: block;
  }
  z-index: 1;
`

const item = css`
  padding: 11px;
`

const itemWithChildren = css`
  cursor: pointer;

  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    vertical-align: 4px;
    content: "";
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &:hover + ${ChildList} {
    display: block;
  }
`

const TextItem = styled.span`
  ${item}
  ${condition(`$hasChildren`, itemWithChildren)}
`

const LinkItem = styled(Link)`
  ${item}
  ${condition(`$hasChildren`, itemWithChildren)}
`

const ItemWrapper = styled.li`
  &,
  a {
    color: white;
  }

  ${notCondition(`child`)` 
    display: inline-block;

    &:not(:last-child)::after {
      content: "/";
    }
  `}

  ${condition(`child`)` 
    display: block;
  `}
`

export default function BreadcrumbItem ({ route, params, label, children = [], isLast = false, child = false }) {
  const hasChildren = !!children.length

  return (
    <ItemWrapper data-testid={`breadcrumb_${child ? `child_` : ``}item`} key={route} child={child}>
      {isLast || !route ? (
        <TextItem $hasChildren={hasChildren}>{label}</TextItem>
      ) : (
        <LinkItem
          route={route}
          params={params}
          $hasChildren={hasChildren}
          data-testid={`breadcrumb_${child ? `child_` : ``}item_link`}
        >
          {label}
        </LinkItem>
      )}
      {hasChildren && (
        <ChildList>
          {children.map((childItem, childIndex) => (
            <BreadcrumbItem key={childItem.label} {...childItem} index={childIndex} child />
          ))}
        </ChildList>
      )}
    </ItemWrapper>
  )
}

BreadcrumbItem.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string,
  params: PropTypes.object,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      params: PropTypes.object,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
  isLast: PropTypes.bool,
  child: PropTypes.bool,
}
