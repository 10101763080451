import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath((t, coBrand) =>
    t(`pages:coBrandTunnelAgent.path`, {
      coBrandSlug: coBrand.slug,
    }),
  ),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual }, { allCoBrandProductAgent }) => {
    const getI18n = require(`../../translation/i18n`).default

    return allCoBrandProductAgent.group.map(({ nodes }) => {
      const coBrand = nodes[0].coBrand
      const locale = coBrand.brandLocale
      const { t } = getI18n(locale)

      return {
        path: route.createPath(isMultiLingual, t, { ...coBrand, locale }),
        component: `./src/modules/coBrand/coBrandTunnelAgent.page.js`,
        context: {
          locale,
          coBrandOriginalId: coBrand.originalId,
          coBrandCode: coBrand.brandCode,
          indexed: false,
          preventHomeBreadcrumb: true,
          hideSalesPhone: true,
          disableLogoLink: true,
          translatable: true,
          translatePageId: `coBrandTunnelAgent-${coBrand.brandCode}`,
          translatePageQuery: `?step=login`,
        },
      }
    })
  }

  route.query = `
    allCoBrandProductAgent {
      group(field: coBrand___slug) {
        nodes {
          coBrand {
            originalId
            slug
            brandLocale
            brandCode
          }
        }
      }
    } 
  `
}

export default route
