import { css } from "styled-components"
import { useWindowResize } from "@homeserve/react-hooks"

const RESPONSIVE_MENU_BREAKPOINT = 768

export function responsiveHamburger (...styles) {
  return css`
    @media (min-width: ${RESPONSIVE_MENU_BREAKPOINT}px) {
      ${css(...styles)}
    }
  `
}

export function useResponsiveMenu () {
  const { width } = useWindowResize()

  return width > 0 && width < RESPONSIVE_MENU_BREAKPOINT
}
