import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { toPx } from "../modules/structure/theme"
import breakpoint from "styled-components-breakpoint"

const maxWidth = prop => props => toPx(props.theme.maxWidth[props[prop]]) || `100%`

const maxWidthStyle = css`
  position: relative;
  max-width: ${maxWidth(`contentWidth`)};
  width: 100%;
  margin: 0 auto;
`

const Background = styled.section.attrs(props => ({
  padding: props.padding ? `24px` : 0,
}))`
  background-color: ${props => props.bgColor};
  margin: 0 auto;
  padding: ${props => props.padding} ${props => (props.bgFullWidthMobile ? 0 : `20px`)};
  width: 100%;
  max-width: ${maxWidth(`bgWidth`)};

  ${breakpoint(`medium`)`
    padding: ${props => props.padding} 0;
  `}
`

const MaxWidth = styled.div`
  ${maxWidthStyle};
`

const BackgroundMaxWidth = styled(Background)`
  ${maxWidthStyle};
`

function Section ({
  children,
  as = `section`,
  bgColor,
  bgWidth = `medium`,
  contentWidth = `medium`,
  padding = false,
  bgFullWidthMobile = false,
}) {
  // We can squash background and maxWidth components
  if (bgWidth === contentWidth) {
    return (
      <BackgroundMaxWidth
        data-testid={arguments[0][`data-testid`] || undefined}
        as={as}
        bgColor={bgColor}
        bgWidth={bgWidth}
        padding={padding}
        bgFullWidthMobile={bgFullWidthMobile}
        contentWidth={contentWidth}
      >
        {children}
      </BackgroundMaxWidth>
    )
  }

  return (
    <Background
      data-testid={arguments[0][`data-testid`] || undefined}
      as={as}
      bgColor={bgColor}
      bgWidth={bgWidth}
      padding={padding}
      bgFullWidthMobile={bgFullWidthMobile}
    >
      <MaxWidth contentWidth={contentWidth}>{children}</MaxWidth>
    </Background>
  )
}

Section.propTypes = {
  as: PropTypes.string,
  bgColor: PropTypes.string,
  bgWidth: PropTypes.oneOf([`full`, `large`, `medium`]),
  contentWidth: PropTypes.oneOf([`full`, `large`, `medium`]),
  padding: PropTypes.bool,
  bgFullWidthMobile: PropTypes.bool,
  children: PropTypes.any,
}

export default Section
