import { createMultiLingualPath } from "../navigation.utils"

const route = {
  createPath: createMultiLingualPath(t => t(`pages:faq.path`)),
}

if (SERVER) {
  route.createPages = ({ isMultiLingual, locales }) => {
    const getI18n = require(`../../translation/i18n`).default

    return locales.map(locale => {
      const { t } = getI18n(locale)

      if (APP_CONFIG.featureFlags.faqSmartTribune) {
        return {
          path: route.createPath(isMultiLingual, t, { locale }),
          component: `./src/modules/faq/faqSmartTribune.page.js`,
          context: {
            preventHomeBreadcrumb: true,
          },
        }
      }

      return {
        path: route.createPath(isMultiLingual, t, { locale }),
        component: `./src/modules/faq/faq.page.js`,
        context: {
          meta: {
            title: t(`pages:faq.title`),
            description: t(`pages:faq.description`),
          },
          categories: APP_CONFIG.faqCategories,
          breadcrumb: [{ label: APP_CONFIG.partnerFullName }, { label: t(`pages:faq.breadcrumb`) }],
        },
      }
    })
  }
}

export default route
