import { tokenize } from "../../utils/format"
import { localeToLang } from "../translation/translate.utils"

export function createConfig () {
  const config = {
    abtasty: {
      scriptUrl: APP_CONFIG.abtasty.url,
    },
    didomi: {
      scriptUrl: APP_CONFIG.didomi.url + `didomi/loader.js`,
    },
    googlemaps: {
      scriptUrl: tokenize(APP_CONFIG.googlemaps.url, {
        token: APP_CONFIG.googlemaps.token,
        callback: `thirdPartiesGoogleMapsCallback`,
      }),
      onBeforeLoad: callback => {
        window.thirdPartiesGoogleMapsCallback = callback
      },
    },
    gtm: {
      scriptUrl: `${APP_CONFIG.gtm.url}?id=${APP_CONFIG.gtm.token}`,
    },
    stripe: {
      scriptUrl: APP_CONFIG.stripe.url,
    },
    captcha: {
      scriptUrl: APP_CONFIG.captcha.url,
      onLoad: callback => {
        window.grecaptcha.ready(callback)
      },
    },
    smartTribune: {
      scriptUrl: APP_CONFIG.smartTribune.scriptUrl,
      onBeforeLoad: (callback, locale) => {
        function eventHandler (event) {
          window.removeEventListener(`STFAQLoaded`, eventHandler)

          event.detail.init({
            analyticsUA: APP_CONFIG.smartTribune.analyticsUA,
            kbId: APP_CONFIG.smartTribune.kbId,
            locale: localeToLang(locale),
            cookieOptin: true,
            filters: {
              thematics: APP_CONFIG.smartTribune.thematics,
              tags: APP_CONFIG.smartTribune.tags,
            },
          })

          callback()
        }

        window.addEventListener(`STFAQLoaded`, eventHandler)
      },
    },
  }

  if (APP_CONFIG.featureFlags.chat) {
    config.chat = {
      scriptUrl: APP_CONFIG.chat.url,
      onLoad: callback => {
        window.CXBus.configure({
          debug: process.env.ENVIRONMENT === `dev`,
          pluginsPath: APP_CONFIG.chat.pluginsUrl,
        })
        window.CXBus.loadPlugin(`widgets-core`)

        callback()
      },
    }
  }

  return config
}
