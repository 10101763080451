import getI18n from "./src/modules/translation/i18n"
import { loadableReady } from "@loadable/component"
import "picturefill/dist/picturefill.min"
import "picturefill/dist/plugins/mutation/pf.mutation.min"

import appWrapper from "./src/layouts/appWrapper"
import { sentryInit } from "./src/modules/thirdParties/sentry"
import { render, hydrate } from "react-dom"
import "react-toastify/dist/ReactToastify.css"
import { useDateOrMockDate } from "./src/utils/time"

// Polyfill <img srcSet sizes /> for IE 11
window.picturefill({
  reevaluate: true,
})

export const wrapPageElement = appWrapper

export const onInitialClientRender = () => {
  if (process.env.ENVIRONMENT === `mock`) {
    window.___GATSBY_INITIAL_RENDER_COMPLETE__ = true
  }
}

function testStorage (storage) {
  const test = `__storage_test__`

  try {
    storage.setItem(test, test)
    storage.removeItem(test)

    return true
  } catch (e) {
    return false
  }
}

function updateLocalstorage () {
  if (!testStorage(window.localStorage)) {
    return false
  }

  const appVersion = APP_CONFIG.storage.localStorageVersion
  const userVersion = (() => {
    try {
      return JSON.parse(window.localStorage.getItem(`version`))
    } catch (e) {
      return ``
    }
  })()

  if (userVersion === appVersion) {
    return false
  }

  if (userVersion) {
    window.localStorage.clear()
  }

  window.localStorage.setItem(`version`, JSON.stringify(appVersion))
  window.localStorage.setItem(`timestamp`, useDateOrMockDate().now())

  return !!userVersion
}

export function onClientEntry () {
  sentryInit()

  if (process.env.ENVIRONMENT === `mock`) {
    const MockDate = require(`./e2e/mocks/mockdate`).default
    const mockDate = window.sessionStorage.getItem(`mockDate`)
    MockDate.set(mockDate ? JSON.parse(mockDate) : `2020-09-28T10:00:00`)
  }

  if (updateLocalstorage()) {
    window.location.reload()
  }
}

export function shouldUpdateScroll ({ prevRouterProps, routerProps: { location } }) {
  if (
    prevRouterProps &&
    prevRouterProps.location.pathname === location.pathname &&
    prevRouterProps.location.search !== location.search
  ) {
    return false
  }

  return true
}

export function replaceHydrateFunction () {
  const langPrefixIndex = process.env.PATH_PREFIX ? 2 : 1
  const lang = window.location.pathname.split(`/`)[langPrefixIndex]
  const { i18n } = getI18n(`${lang}_${APP_CONFIG.country.toUpperCase()}`)
  const hydrateOrRender = module.hot ? render : hydrate

  return (element, container, callback) => {
    let libLoaded = 0

    const mayRender = () => {
      if (++libLoaded === 2) {
        hydrateOrRender(element, container, callback)
      }
    }

    loadableReady(mayRender)
    i18n.loadNamespaces(APP_CONFIG.i18nDefaultNamespaces, mayRender)
  }
}

export const onServiceWorkerUpdateReady = () => {
  // Need to flush sessionStorage to be sure the new version won't use obsolete storage
  if (testStorage(window.sessionStorage)) {
    window.sessionStorage.clear()
  }

  window.location.reload()
}
