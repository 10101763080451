import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

export function useWebsiteConfiguration () {
  const {
    websiteConfiguration: { locales, endPriceValidityDate, salesPhone, campaignPhone, blacklistedPostalCodes },
  } = useStaticQuery(graphql`
    query {
      websiteConfiguration {
        locales
        endPriceValidityDate
        salesPhone
        campaignPhone
        blacklistedPostalCodes
      }
    }
  `)

  return useMemo(
    () => ({
      locales,
      endPriceValidityDate,
      salesPhone: salesPhone || ``,
      campaignPhone: campaignPhone || ``,
      blacklistedPostalCodes: blacklistedPostalCodes,
    }),
    [],
  )
}
