import { createCaptchaHeader, fetchSite } from "../../utils/fetch"
import { formatFromBasketToLocalstorage } from "./payment.utils"

export function submitPayment (object) {
  return fetchSite(`/purchases/order`, {
    method: `POST`,
    body: JSON.stringify(object),
  })
}

export function postPurchasesBasket (product, step1, step2, voucher, locale, captchaToken) {
  const country = APP_CONFIG.country.toUpperCase()

  const data = { ...step1, ...step2 }

  if (data.address !== undefined) {
    data.address = data.street
    delete data.street
  }

  if (data.billingAddress !== undefined) {
    data.billingAddress = data.billingStreet || ``
    delete data.billingStreet
  }

  const body = JSON.stringify({
    ...(voucher ? { voucher } : null),
    coverit: product.originalId || product.id,
    coveritVariant: product.impactProduct.variants[0].id,
    user: {
      ...data,
      locale,
      country,
      billingCountry: data.differentBilling ? country : ``,
    },
  })

  return fetchSite(`/purchases/basket`, {
    method: `POST`,
    headers: createCaptchaHeader(captchaToken),
    body,
  })
}

export function submitStep3 (sessionId, values, captchaToken) {
  return fetchSite(`/baskets/${sessionId}`, {
    method: `PUT`,
    headers: createCaptchaHeader(captchaToken),
    body: JSON.stringify(values),
  })
}

export function getBasketFromEncryptId (sessionId) {
  return fetchSite(`/baskets/${sessionId}`).then(formatFromBasketToLocalstorage)
}

export function postPaymentIntent (sessionId, type = `card`) {
  return fetchSite(`/purchases/payment_intent`, {
    method: `POST`,
    body: JSON.stringify({ sessionId, type }),
  })
}
